import api from '@/utils/api';

export default {
  getTree: ({ token, id }) => api.get(`/vendor/chart/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
  addNode: ({ token, data }) => api.post('/vendor', data, { headers: { Authorization: `Bearer ${token}` } }),
  getNodeDetail: ({ token, id }) => api.get(`/vendor/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
  updateNodeDetail: ({ token, id, detail }) => api.put(`/vendor/${id}`, detail, { headers: { Authorization: `Bearer ${token}` } }),
  removeNode: ({ token, id }) => api.del(`/vendor/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
  checkAccountExist: ({ token, account }) => api.get(`/vendor/exist/${account}`, { headers: { Authorization: `Bearer ${token}` } }),
};
