import authAPI from '@/service/auth';
import jwtDecode from 'jwt-decode';

localStorage.token = localStorage.token ?? '';
localStorage.id = localStorage.id ?? 0;
localStorage.purview = localStorage.purview ?? '{}';
localStorage.detail = localStorage.detail ?? '{}';

export default {
  namespaced: true,
  state: {
    token: localStorage.token,
    id: JSON.parse(localStorage.id),
    purview: JSON.parse(localStorage.purview),
    detail: JSON.parse(localStorage.detail),
  },

  getters: {
  },

  actions: {
    logout: async ({ state, commit }) => {
      await authAPI.logout({ token: state.token })
        .then(() => {
          commit('setToken', '');
          commit('setDetail', {});
          commit('setPurview', {});
          commit('setId', 0);
        }, (err) => console.log('logout err.', err));
    },
    getToken: async ({ commit }, { auth }) => {
      await authAPI.getToken({ auth })
        .then((data) => {
          if (data.data !== 'CustomerNotExist' || data.data === 'WrongPassword') {
            commit('setToken', data.data);
            commit('setId', parseInt(jwtDecode(data.data).nameid, 10));
          } else {
            throw data;
          }
        });
    },
    getUserDetail: async ({ state, commit, rootState }) => {
      await authAPI.getUserDetail({ token: rootState.Auth.token, id: state.id })
        .then((data) => {
          commit('setDetail', data.data);
          commit('setPurview', rootState.Permission.permission
            .filter((p) => state.detail.permissionIds.includes(p.id))
            .map((p) => p.systemName)
            .reduce((a, b) => {
              const tmp = a;
              tmp[b] = true;
              return a;
            }, {}));
        },
        (err) => console.log('nothing.', err));
    },
  },

  mutations: {
    setToken: (state, value) => {
      state.token = value;
      localStorage.token = value;
    },
    setDetail: (state, value) => {
      state.detail = value;
      localStorage.detail = JSON.stringify(value);
    },
    setPurview: (state, value) => {
      state.purview = value;
      localStorage.purview = JSON.stringify(value);
    },
    setId: (state, value) => {
      state.id = value;
      localStorage.id = JSON.stringify(value);
    },
  },
};
