<template lang="pug">
el-container#root(style="height: 100%;")
  el-aside.main-sideBar(width="280px" style="background-color: #545c64;")
    .div(style="background-color: rgb(0, 63, 81); padding: 10px;")
      el-image.img-fluid.sideBarLogo(
        :src="logo"
      )
    el-menu.el-menu-vertical-demo(
      default-active="2"
      background-color="#545c64"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
      style="height: calc(100% - 200px); width: 280px; border: 0; padding: 20px 0px 0px 0px"
    )
      el-menu-item.titleName(v-if="$store.state.Auth.purview.import" index="import" route="/import")
        i.el-icon-setting
        span(slot="title") {{ $t('menuOption.importDPC')}}
      el-menu-item.titleName(v-if="!isOnlyImport" index="system" route="/system")
        i.el-icon-setting
        span(slot="title") {{ $t('menuOption.systemManagement')}}
      el-submenu.titleName(v-if="$store.state.Auth.purview.notify" index="notification")
        template(slot="title") {{ $t('menuOption.pushManagement')}}
        el-menu-item.titleSmallName(index="notification-message" route="/notification/message")
          i.el-icon-setting
          span(slot="title") {{ $t('menuOption.newsPush')}}
        el-menu-item.titleSmallName(index="notification-review" route="/notification/review")
          i.el-icon-setting
          span(slot="title") {{ $t('menuOption.pushBroadcastReview')}}
        el-menu-item.titleSmallName(index="notification-record" route="/notification/record")
          i.el-icon-setting
          span(slot="title") {{ $t('menuOption.pushRecord')}}
      el-submenu(v-if="$store.state.Auth.purview.remote" index="service")
        template(slot="title") {{ $t('menuOption.remoteService')}}
        el-menu-item.titleSmallName(index="service-remote" route="/service/remote")
          i.el-icon-setting
          span(slot="title") {{ $t('menuOption.remoteRepair')}}
        el-menu-item.titleSmallName(
          v-if="$store.state.Auth.purview.Admin"
          index="service-record"
          route="/service/record"
        )
          i.el-icon-setting
          span(slot="title") {{ $t('menuOption.serviceRecord')}}
    .div(id="aside-footer")
      .button(@click="logout" id="logout-button")
          i.el-icon-switch-button
          span.ml-2.
            {{ $t('logoutBtn') }}
      button.globeBtnFont.btn.btn-link.text-white.mt-2(@click="openDialog")
        font-awesome-icon(:icon="['fas', 'globe-asia']").
        span.ml-2.
          {{ $t('currentLanguage') }}
  el-main(style="padding: 50px 40px 30px 40px;")
    el-header(height="40px")
      el-breadcrumb.headerTitle(separator="/")
        el-breadcrumb-item(
          v-for="(name, index) in routeName"
          :key="index"
        ) {{ name }}
    transition(name="el-zoom-in-center")
      router-view.cardnoshadow(style="padding: 0px 20px 0px 20px")
  LangDialog(:visible='isOpen' @update:visible="closeDialog")
</template>

<style>
    body,
    html {
        height: 100%;
        margin: 0px;
    }
    .el-menu-item{
    font-size:1rem!important;
    margin-top:0.3rem;
    }
    .el-submenu__title{
    font-size:1rem!important;
    margin-top:0.3rem;

    }
    .titleSmallName{
    font-size:0.8rem!important;
    padding-left:50px!important;
    }
    #root {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    #aside-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        height: 100px;
        font-size:1rem;
        padding: 0px 0px 20px 0px;
    }

    #logout-button {
        color:white;
    }
    .globeBtnFont{
        font-size:1rem!important;
    }
    .sideBarLogo{
      width:150px;
    }
    .main-sideBar{
        box-shadow: 10PX 0px 20px rgba(0,0,0,.35),0 10px 10px rgba(0,0,0,.22)
        !important;
    }
    .headerTitle{
      font-size:30px!important;
    }
    .cardnoshadow {
      box-shadow: 5px 5px 5px rgbA(0,0,0,.25)!important;
      border: solid 1px rgbA(0,0,0,.25)!important;
      padding:15px!important;
}

</style>

<script>
import LangDialog from '@/components/LangDialog/index.vue';
import { mapState } from 'vuex';

export default {
  components: {
    LangDialog,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isOnlyImport() {
      let result = false;
      const keys = Object.keys(this.$store.state.Auth.purview);
      if (keys.length === 1) {
        result = keys.includes('import');
      }

      return result;
    },
    ...mapState({
      logo: (state) => state.Logo,
    }),
    routeName() {
      return this.$t(`route.${this.$route.path}`).split('/');
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/logout')
        .then(() => { document.location.reload(); });
    },
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
  },
  created() {
  },
};
</script>
