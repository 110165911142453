<template lang="pug">
div
  el-dialog(
    :title="title"
    :visible.sync="visible"
    :before-close="closeDialog"
    width="60%"
    center
  )
    el-input(
      type="textarea"
      :rows="12"
      :placeholder="$t('service.setting.placeholder')"
      v-model="message" required
      )
    span.dialog-footer(slot="footer")
      el-button(@click="closeDialog") {{ cancelText }}
      el-button(type="primary" @click="confirm") {{ confirmText }}
  div
    el-button(
      type="success"
      @click="fixDone"
      v-loading.fullscreen.lock="fullscreenLoading"
      ) {{ $t('service.setting.finish') }}
  el-tabs(v-model="activeName" type="card")
    el-tab-pane(:label="$t('service.setting.config')" name="first")
      div
        div
          p {{ $t('service.setting.pMax') }}/kgf/cm²
          el-input(
            v-model="$store.state.WS.basicConfig.pMax"
            :placeholder="$t('service.setting.pMax')"
            )
        div
          p {{ $t('service.setting.pMax') }}/kgf/cm²
          el-input(
            v-model="$store.state.WS.basicConfig.pMin"
            :placeholder="$t('service.setting.pMax')"
            )
        div
          p {{ $t('service.setting.pMax') }}/sec
          el-input(
            v-model="$store.state.WS.basicConfig.delay"
            :placeholder="$t('service.setting.delay')"
            )
      div
        div
          p {{ $t('service.setting.unit') }}
          el-select(
            @change="onUnitChange"
            v-model="$store.state.WS.basicConfig.unit"
            :placeholder="$t('service.setting.choice')"
            style="width: 100%;"
          )
            el-option(
              v-for="(item, index) in unitItem"
              :key="index"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          p {{ $t('service.setting.pDryTime') }}/sec
          el-input(
            v-model="$store.state.WS.basicConfig.pDryTime"
            :placeholder="$t('service.setting.pDryTime')"
            )
        div
          p {{ $t('service.setting.pDry') }}/kgf/cm²
          el-input(
            v-model="$store.state.WS.basicConfig.pDry"
            :placeholder="$t('service.setting.pDry')"
            )
        div
          p {{ $t('service.setting.autoReset') }}/sec
          el-input(
            v-model="$store.state.WS.basicConfig.autoReset"
            :placeholder="$t('service.setting.delay')"
            )
        div
          p {{ $t('service.setting.St') }}（St)
          el-select(value :placeholder="$t('service.setting.choice')" style="width: 100%;")
            el-option(
              v-for="(item, index) in stItem"
              :key="item.value"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          p {{ $t('service.setting.ct') }}（Ct)
          el-select(value :placeholder="$t('service.setting.choice')" style="width: 100%;")
            el-option(
              v-for="item in ctItem"
              :key="item.value"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          p {{ $t('service.setting.ut') }}（Ut）/kgf/cm²
          el-input(v-model="test" :placeholder="$t('service.setting.delay')")
        div
          p {{ $t('service.setting.ot') }}（Ot）/kgf/cm²
          el-input(v-model="test" :placeholder="$t('service.setting.delay')")
      div
        div
          p {{ $t('service.setting.dt') }}（Dt）
          el-select(
            v-model="$store.state.WS.basicConfig.dt"
            :placeholder="$t('service.setting.choice')"
            style="width: 100%;"
            )
            el-option(
              v-for="(item, index) in dtItem"
              :key="item.value"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          p {{ $t('service.setting.to') }}（To）
          el-select(v-model="to" :placeholder="$t('service.setting.choice')" style="width: 100%;")
            el-option(
              v-for="item in toItem"
              :key="item.value"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          p {{ $t('service.setting.tf') }}（Tf）
          el-select(v-model="tf" :placeholder="$t('service.setting.choice')" style="width: 100%;")
            el-option(
              v-for="item in tfItem"
              :key="item.value"
              :label="item.message"
              :value="item.value"
            )
              span(style="float: left") {{ item.message }}
              span(style="float: right; color: #8492a6; font-size: 13px") {{ item.value }}
        div
          el-button(type="primary" @click="save") {{ $t('service.setting.save') }}
          el-button(
            type="warning"
            @click="restoreToCustomer"
            ) {{ $t('service.setting.restoreCustomer') }}
          el-button(
            type="danger"
            @click="restoreToOrigin"
            ) {{ $t('service.setting.restoreFactory') }}
    el-tab-pane(:label="$t('service.setting.alarm')")
      el-table(:data="allAlarm" style="width: 100%" max-height="300")
        el-table-column(prop="createAt" :label="$t('service.setting.date')").
        el-table-column(prop="message" :label="$t('service.setting.errorMessage')").
    el-tab-pane(:label="$t('service.setting.record')")
      el-table(:data="fixHistory" style="width: 100%" max-height="300")
        el-table-column(prop="createAt" :label="$t('service.setting.date')").
        el-table-column(prop="message" :label="$t('service.setting.errorMessage')").
    el-tab-pane(:label="$t('service.setting.activateAmount')")
      el-table(:data="fixHistory" style="width: 100%" max-height="300")
        el-table-column(prop="createAt" :label="$t('service.setting.date')").
        el-table-column(prop="message" :label="$t('service.setting.errorMessage')").
    el-tab-pane(:label="$t('service.setting.runningTime')")
      el-table(:data="fixHistory" style="width: 100%" max-height="300")
        el-table-column(prop="createAt" :label="$t('service.setting.date')").
        el-table-column(prop="message" :label="$t('service.setting.errorMessage')").
</template>

<script>
import moment from 'moment';
import remote from '@/service/remote';
import { compareDesc, format } from 'date-fns';

export default {
  data() {
    return {
      allAlarm: [],
      unitItem: [
        { value: 0, message: 'bar' },
        { value: 1, message: 'kgf/cm²' },
        { value: 2, message: 'psi' },
      ],
      stItem: [
        { value: 0, message: 'St-0' },
        { value: 1, message: 'St-0.5' },
        { value: 2, message: 'St-1' },
        { value: 4, message: 'St-2' },
        { value: 6, message: 'St-3' },
        { value: 8, message: 'St-4' },
        { value: 10, message: 'St-5' },
      ],
      ctItem: [
        { value: 0, message: 'Ct-0' },
        { value: 10, message: 'Ct-10' },
        { value: 20, message: 'Ct-20' },
        { value: 30, message: 'Ct-30' },
        { value: 40, message: 'Ct-40' },
        { value: 50, message: 'Ct-50' },
        { value: 60, message: 'Ct-60' },
      ],
      dtItem: [
        { value: 0, message: 'Dt-0' },
        { value: 5, message: 'Dt-5' },
        { value: 10, message: 'Dt-10' },
        { value: 15, message: 'Dt-15' },
        { value: 20, message: 'Dt-20' },
        { value: 25, message: 'Dt-25' },
        { value: 30, message: 'Dt-30' },
        { value: 35, message: 'Dt-35' },
        { value: 40, message: 'Dt-40' },
        { value: 45, message: 'Dt-45' },
        { value: 50, message: 'Dt-50' },
        { value: 55, message: 'Dt-55' },
        { value: 60, message: 'Dt-60' },
        { value: 65, message: 'Dt-65' },
        { value: 70, message: 'Dt-70' },
        { value: 75, message: 'Dt-75' },
        { value: 80, message: 'Dt-80' },
        { value: 85, message: 'Dt-85' },
        { value: 90, message: 'Dt-90' },
      ],
      toItem: [
        { value: 0, message: 'To-0' },
        { value: 1, message: 'To-0.1' },
        { value: 2, message: 'To-0.2' },
        { value: 3, message: 'To-0.3' },
        { value: 4, message: 'To-0.4' },
        { value: 5, message: 'To-0.5' },
        { value: 10, message: 'To-1.0' },
        { value: 11, message: 'To-1.1' },
        { value: 12, message: 'To-1.2' },
        { value: 13, message: 'To-1.3' },
        { value: 14, message: 'To-1.4' },
        { value: 15, message: 'To-1.5' },
        { value: 20, message: 'To-2.0' },
        { value: 21, message: 'To-2.1' },
        { value: 22, message: 'To-2.2' },
        { value: 23, message: 'To-2.3' },
        { value: 24, message: 'To-2.4' },
        { value: 25, message: 'To-2.5' },
        { value: 30, message: 'To-3.0' },
        { value: 31, message: 'To-3.1' },
        { value: 32, message: 'To-3.2' },
        { value: 33, message: 'To-3.3' },
        { value: 34, message: 'To-3.4' },
        { value: 35, message: 'To-3.5' },
        { value: 40, message: 'To-4.0' },
        { value: 41, message: 'To-4.1' },
        { value: 42, message: 'To-4.2' },
        { value: 43, message: 'To-4.3' },
        { value: 44, message: 'To-4.4' },
        { value: 45, message: 'To-4.5' },
        { value: 50, message: 'To-5.0' },
        { value: 51, message: 'To-5.1' },
        { value: 52, message: 'To-5.2' },
        { value: 53, message: 'To-5.3' },
        { value: 54, message: 'To-5.4' },
        { value: 55, message: 'To-5.5' },
        { value: 60, message: 'To-6.0' },
        { value: 61, message: 'To-6.1' },
        { value: 62, message: 'To-6.2' },
        { value: 63, message: 'To-6.3' },
        { value: 64, message: 'To-6.4' },
        { value: 65, message: 'To-6.5' },
        { value: 70, message: 'To-7.0' },
        { value: 71, message: 'To-7.1' },
        { value: 72, message: 'To-7.2' },
        { value: 73, message: 'To-7.3' },
        { value: 74, message: 'To-7.4' },
        { value: 75, message: 'To-7.5' },
        { value: 80, message: 'To-8.0' },
      ],
      tfItem: [
        { value: 0, message: 'Tf-0' },
        { value: 1, message: 'Tf-0.1' },
        { value: 2, message: 'Tf-0.2' },
        { value: 3, message: 'Tf-0.3' },
        { value: 4, message: 'Tf-0.4' },
        { value: 5, message: 'Tf-0.5' },
        { value: 10, message: 'Tf-1.0' },
        { value: 11, message: 'Tf-1.1' },
        { value: 12, message: 'Tf-1.2' },
        { value: 13, message: 'Tf-1.3' },
        { value: 14, message: 'Tf-1.4' },
        { value: 15, message: 'Tf-1.5' },
        { value: 20, message: 'Tf-2.0' },
        { value: 21, message: 'Tf-2.1' },
        { value: 22, message: 'Tf-2.2' },
        { value: 23, message: 'Tf-2.3' },
        { value: 24, message: 'Tf-2.4' },
        { value: 25, message: 'Tf-2.5' },
        { value: 30, message: 'Tf-3.0' },
        { value: 31, message: 'Tf-3.1' },
        { value: 32, message: 'Tf-3.2' },
        { value: 33, message: 'Tf-3.3' },
        { value: 34, message: 'Tf-3.4' },
        { value: 35, message: 'Tf-3.5' },
        { value: 40, message: 'Tf-4.0' },
        { value: 41, message: 'Tf-4.1' },
        { value: 42, message: 'Tf-4.2' },
        { value: 43, message: 'Tf-4.3' },
        { value: 44, message: 'Tf-4.4' },
        { value: 45, message: 'Tf-4.5' },
        { value: 50, message: 'Tf-5.0' },
        { value: 51, message: 'Tf-5.1' },
        { value: 52, message: 'Tf-5.2' },
        { value: 53, message: 'Tf-5.3' },
        { value: 54, message: 'Tf-5.4' },
        { value: 55, message: 'Tf-5.5' },
        { value: 60, message: 'Tf-6.0' },
        { value: 61, message: 'Tf-6.1' },
        { value: 62, message: 'Tf-6.2' },
        { value: 63, message: 'Tf-6.3' },
        { value: 64, message: 'Tf-6.4' },
        { value: 65, message: 'Tf-6.5' },
        { value: 70, message: 'Tf-7.0' },
        { value: 71, message: 'Tf-7.1' },
        { value: 72, message: 'Tf-7.2' },
        { value: 73, message: 'Tf-7.3' },
        { value: 74, message: 'Tf-7.4' },
        { value: 75, message: 'Tf-7.5' },
        { value: 80, message: 'Tf-8.0' },
      ],
      oldUnit: 0,
      test: 0,
      activeName: 'first',
      visible: false,
      title: this.$t('service.setting.serviceRemark'),
      message: this.$t('service.setting.noRemark'),
      cancelText: this.$t('service.setting.cancel'),
      confirmText: this.$t('service.setting.confirm'),
      fixHistory: [],
      startTime: undefined,
      fullscreenLoading: false,
    };
  },
  computed: {
    unitStr() {
      const unitString = [
        'bar',
        'kgf/cm²',
        'psi',
      ][this.$store.state.WS.basicConfig.unit];
      return unitString;
    },
    to: {
      get() {
        let result = 0;
        if (this.$store.state.WS.basicConfig.to) {
          const hours = this.$store.state.WS.basicConfig.to.hours();
          const minutes = this.$store.state.WS.basicConfig.to.minutes().toString();
          const tmp = `${hours}${minutes[0]}`;
          result = parseInt(tmp, 10);
        }
        return result;
      },
      set(value) {
        const hours = value.toString().length === 1 ? 0 : value.toString()[0];
        const minutes = value
          .toString().length === 1 ? value.toString()[0] : value.toString()[1];
        const times = moment.duration({
          hours: parseInt(hours, 10),
          minutes: parseInt(minutes, 10) * 10,
        });
        this.$store.state.WS.basicConfig.to = times;
      },
    },
    tf: {
      get() {
        let result = 0;
        if (this.$store.state.WS.basicConfig.tf) {
          const hours = this.$store.state.WS.basicConfig.tf.hours();
          const minutes = this.$store.state.WS.basicConfig.tf.minutes().toString();
          const tmp = `${hours}${minutes[0]}`;
          result = parseInt(tmp, 10);
        }
        return result;
      },
      set(value) {
        const hours = value.toString().length === 1 ? 0 : value.toString()[0];
        const minutes = value
          .toString().length === 1 ? value.toString()[0] : value.toString()[1];
        const times = moment.duration({
          hours: parseInt(hours, 10),
          minutes: parseInt(minutes, 10) * 10,
        });
        this.$store.state.WS.basicConfig.tf = times;
      },
    },
  },
  methods: {
    restoreToCustomer() {
      this.$store.dispatch('WS/getBasic');
      this.oldUnit = this.$store.state.WS.basicConfig.unit;
    },
    restoreToOrigin() {
      this.$store.dispatch('WS/restoreToOrigin');
      this.oldUnit = this.$store.state.WS.basicConfig.unit;
    },
    onUnitChange() {
      [
        [
          () => {},
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 1.02).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 1.02).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 1.02).toFixed(2), 10,
            );
          },
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 14.5).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 14.5).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 14.5).toFixed(2), 10,
            );
          },
        ],
        [
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 0.98).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 0.98).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 0.98).toFixed(2), 10,
            );
          },
          () => {},
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 14.22).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 14.22).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 14.22).toFixed(2), 10,
            );
          },
        ],
        [
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 0.07).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 0.07).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 0.07).toFixed(2), 10,
            );
          },
          () => {
            this.$store.state.WS.basicConfig.pMax = parseFloat(
              (this.$store.state.WS.basicConfig.pMax * 0.07).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pMin = parseFloat(
              (this.$store.state.WS.basicConfig.pMin * 0.07).toFixed(2), 10,
            );
            this.$store.state.WS.basicConfig.pDry = parseFloat(
              (this.$store.state.WS.basicConfig.pDry * 0.07).toFixed(2), 10,
            );
          },
          () => {},
        ],
      ][this.oldUnit][this.$store.state.WS.basicConfig.unit]();
      console.log('unit change old', this.oldUnit);
      console.log('unit change new', this.$store.state.WS.basicConfig.unit);
      this.oldUnit = this.$store.state.WS.basicConfig.unit;
    },
    closeDialog() {
      this.visible = false;
    },
    async confirm() {
      this.fullscreenLoading = true;
      const result = await remote.saveRemoteInfo({
        token: this.$store.state.Auth.token,
        data: {
          RemoteStart: format(parseInt(this.startTime, 10), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          RemoteEnd: format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'),
          Commit: this.message,
          Sn: this.$store.state.WS.sn,
          VendorId: this.$store.state.Auth.id,
        },
      }).then(() => {
        this.$message({
          message: this.$t('service.setting.serviceSuccess'),
          type: 'success',
        });
      }).catch((error) => {
        console.log('error.response: ', error.response.data.Message);
        this.$message({
          message: this.$t('service.setting.serviceFail'),
          type: 'warning',
        });
      }).finally(
        setTimeout(() => {
          this.fullscreenLoading = false;
          console.log('save remote', result);
          localStorage.removeItem('remoteStartTime');

          this.$store.dispatch('WS/closeWS');
          this.$store.commit('WS/clean');
          this.$router.push('/service/remote');
        }, 1000),
      );
    },
    save() {
      this.$store.dispatch('WS/saveBasic');
    },
    fixDone() {
      this.visible = true;
      console.log(format(Date.now(), 'yyyy-MM-dd\'T\'HH:mm:ss'));
    },
    transCodeToMessage(code) {
      return {
        '00': this.$t('service.setting.systemStop'),
        E0: this.$t('service.setting.overPressure'),
      }[code];
    },
  },
  async mounted() {
    this.startTime = localStorage.remoteStartTime ?? Date.now();
    localStorage.remoteStartTime = this.startTime;

    this.$store.dispatch('DPC/getAllAlert');

    this.fixHistory = await remote.getAllRemoteInfo({
      token: this.$store.state.Auth.token,
      sn: this.$store.state.WS.sn,
    });
    this.fixHistory = this.fixHistory.data.map((item) => ({
      createAt: item.remoteStart,
      message: item.commit,
    }));

    this.allAlarm = await remote.getAllAlarm({
      token: this.$store.state.Auth.token,
      sn: this.$store.state.WS.sn,
    });
    this.allAlarm = this.allAlarm.data
      .sort((a, b) => compareDesc(new Date(a.createAt), new Date(b.createAt)))
      .map((item) => ({
        createAt: item.createAt,
        message: this.transCodeToMessage(item.alarmString),
      }))
      .slice(0, 20);
    console.log(this.allAlarm);

    if (!this.$store.state.WS.ws) {
      console.log('ws empty');
      this.$store.commit('WS/init');

      setTimeout(() => {
        console.log('get basic');
        this.$store.dispatch('WS/getBasic');
        this.oldUnit = this.$store.state.WS.basicConfig.unit;
      }, 1000);
    } else {
      this.$store.dispatch('WS/getBasic');
      this.oldUnit = this.$store.state.WS.basicConfig.unit;
    }
  },
};
</script>

<style>
    .demo-table-expand {
        font-size: 0;
    }

    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
