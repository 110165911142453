export default class ErrorResponse {
  /**
     *
     * @param {String} ErrorType
     * @param {String} ErrorString
     */
  constructor(ErrorType, ErrorString) {
    this.ErrorType = ErrorType;
    this.ErrorString = ErrorString;
  }
}
