import Vue from 'vue';
import VueRouter from 'vue-router';

import Store from '@/store';

import Login from '@/views/login/index.vue';
import Forget from '@/views/forgetPW/index.vue';
import Reset from '@/views/reset/index.vue';

import Home from '@/views/root/Home/index.vue';

import Import from '@/views/root/import/index.vue';

import System from '@/views/root/system/index.vue';

import NMessage from '@/views/root/notification/message/index.vue';
import NRecord from '@/views/root/notification/record/index.vue';
import NReview from '@/views/root/notification/review/index.vue';

import SRoot from '@/views/root/service/remote/index.vue';
import SRSetting from '@/views/root/service/remote/setting/index.vue';
import SRecord from '@/views/root/service/record/index.vue';
import SRLogin from '@/views/root/service/remote/login/index.vue';

import Root from '@/views/root/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: '登入',
    component: Login,
  },
  {
    path: '/forgetPW',
    name: '忘記密碼',
    component: Forget,
  },
  {
    path: '/reset',
    name: '重設密碼',
    component: Reset,
    props: (route) => ({ query: route.query.key }),
  },
  {
    path: '/',
    component: Root,
    meta: {
      auth: true,
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: '/import',
        name: 'DPC匯入',
        component: Import,
      },
      {
        path: '/system',
        name: '體系管理',
        component: System,
      },
      {
        path: '/notification/message',
        name: '推播管理/訊息推播',
        component: NMessage,
      },
      {
        path: '/notification/record',
        name: '推播管理/推播紀錄',
        component: NRecord,
      },
      {
        path: '/notification/review',
        name: '推播管理/推播審核',
        component: NReview,
      },
      {
        path: '/service/remote',
        name: '遠端服務/遠端維修',
        children: [
          {
            path: '/service/remote',
            name: '遠端服務/遠端維修/登入畫面',
            component: SRLogin,
          },
          {
            path: '/service/remote/setting',
            name: '遠端服務/遠端維修/設定畫面',
            component: SRSetting,
          },
        ],
        component: SRoot,
      },
      {
        path: '/service/record',
        name: '遠端服務/服務紀錄',
        component: SRecord,
      },
    ],
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
  if (to.matched.some((recod) => recod.meta.auth)) {
    const isPass = Store.state.Auth.token !== '';
    if (!isPass) {
      next('/login');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
