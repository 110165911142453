/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import system from '@/service/system';

export default {
  namespaced: true,
  state: {
    tree: [],
    detail: {
      id: 0,
      info: {
        title: '',
        phone: '',
        email: '',
        address: '',
        socialId: 0,
        socialAccount: '',
      },
      purview: {
        remote: false,
        notify: false,
        node: false,
      },
    },
  },
  getters: {},
  actions: {
    getTree: async ({ commit, rootState }) => {
      await system.getTree({ token: rootState.Auth.token, id: rootState.Auth.id })
        .then((data) => {
          commit('setTree', data.data);
        }).catch((err) => {
          commit('setTree', [
            {
              id: 99,
              label: 'EVAK',
              dpc: {
                mountYet: 36,
                mounted: 26,
                registed: 38,
              },
              level: 1,
              purview: {
                remote: true,
                notify: true,
                node: true,
              },
              children: [
                {
                  id: 1,
                  label: '總代理 A',
                  dpc: {
                    mountYet: 36,
                    mounted: 26,
                    registed: 38,
                  },
                  level: 2,
                  purview: {
                    remote: true,
                    notify: true,
                    node: true,
                  },
                  children: [
                    {
                      id: 4,
                      label: '二级 1-1',
                      level: 3,
                      dpc: {
                        mountYet: 36,
                        mounted: 26,
                        registed: 38,
                      },
                      purview: {
                        remote: false,
                        notify: false,
                        node: false,
                      },
                      children: [
                        {
                          id: 9,
                          label: '三级 1-1-1',
                          level: 4,
                          dpc: {
                            mountYet: 36,
                            mounted: 26,
                            registed: 38,
                          },
                          purview: {
                            remote: false,
                            notify: false,
                            node: false,
                          },
                          children: [],
                        },
                        {
                          id: 10,
                          label: '三级 1-1-2',
                          dpc: {
                            mountYet: 36,
                            mounted: 26,
                            registed: 38,
                          },
                          level: 4,
                          purview: {
                            remote: false,
                            notify: false,
                            node: false,
                          },
                          children: [],
                        },
                      ],
                    },
                  ],
                },
                {
                  id: 2,
                  label: '總代理 B',
                  dpc: {
                    mountYet: 36,
                    mounted: 26,
                    registed: 38,
                  },
                  level: 2,
                  purview: {
                    remote: false,
                    notify: false,
                    node: false,
                  },
                  children: [
                    {
                      id: 5,
                      label: '二级 2-1',
                      dpc: {
                        mountYet: 36,
                        mounted: 26,
                        registed: 38,
                      },
                      level: 3,
                      purview: {
                        remote: false,
                        notify: false,
                        node: false,
                      },
                    },
                    {
                      id: 6,
                      label: '二级 2-2',
                      dpc: {
                        mountYet: 36,
                        mounted: 26,
                        registed: 38,
                      },
                      level: 3,
                      purview: {
                        remote: false,
                        notify: false,
                        node: false,
                      },
                    },
                  ],
                },
                {
                  id: 3,
                  label: '總代理 C',
                  dpc: {
                    mountYet: 36,
                    mounted: 26,
                    registed: 38,
                  },
                  level: 2,
                  purview: {
                    remote: false,
                    notify: false,
                    node: false,
                  },
                  children: [
                    {
                      id: 7,
                      label: '二级 3-1',
                      dpc: {
                        mountYet: 36,
                        mounted: 26,
                        registed: 38,
                      },
                      level: 3,
                      purview: {
                        remote: false,
                        notify: false,
                        node: false,
                      },
                    },
                    {
                      id: 8,
                      label: '二级 3-2',
                      dpc: {
                        mountYet: 36,
                        mounted: 26,
                        registed: 38,
                      },
                      level: 3,
                      purview: {
                        remote: false,
                        notify: false,
                        node: false,
                      },
                    },
                  ],
                },
              ],
            },
          ]);
        });
    },
    addNode: async ({ commit, rootState }, { data }) => {
      await system.addNode({ token: rootState.Auth.token, data })
        .catch((err) => console.log('nothing.'));
    },
    getNodeDetail: async ({ commit, rootState }, { node }) => {
      await system.getNodeDetail({ token: rootState.Auth.token, id: node.id })
        .then((data) => commit('setNodeDetail', ({
          id: data.data.id,
          info: {
            title: data.data.fullName,
            phone: data.data.phone,
            company: data.data.company,
            contact: data.data.contact,
            email: data.data.email,
            address: data.data.streetAddress,
            socialId: data.data.socialId ?? 0,
            socialAccount: data.data.socialAccount,
          },
          purview: rootState.Permission.permission
            .filter((p) => data.data.permissionIds.includes(p.id))
            .map((p) => p.systemName)
            .reduce((a, b) => {
              const tmp = a;
              tmp[b] = true;
              return tmp;
            }, {}),
        })))
        .catch((err) => console.log('nothing.'));
    },
    updateNodeDetail: async ({ rootState }, { id, detail }) => {
      await system.updateNodeDetail({ token: rootState.Auth.token, id, detail })
        .catch((err) => console.log('update nothing'));
    },
    removeNode: async ({ rootState, dispatch }, { id }) => {
      await system.removeNode({ token: rootState.Auth.token, id })
        .catch((err) => console.log('nothing'));
      await dispatch('getTree');
    },
    checkAccountExist: async ({ rootState, dispatch }, { account }) => {
      await system.checkAccountExist({ token: rootState.Auth.token, account: encodeURI(account) })
        .then((data) => data.data)
        .catch((err) => console.log('exist nothing.'));
    },
  },
  mutations: {
    setTree: (state, value) => { state.tree = value; },
    setNodeDetail: (state, value) => { state.detail = value; },
  },
};
