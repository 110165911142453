<template lang="pug">
#main.text-light.placeholder-light.remove-focus.d-flex.flex-column.justify-content-center
  .pt-3.mb-3.d-flex.justify-content-center.flex-column.align-items-center
    img.login-logo(:src="images.logo" alt)
    h4.mt-5.
      {{ $t('reset.inputNewPW') }}
  form.d-flex.justify-content-center.flex-column.align-items-center(
    :model="auth"
    ref="form"
  )
    el-input.input-with-select.mt-4(
      :placeholder="$t('reset.inputNewPW')"
      style="width: 350px;"
      v-model="input"
      show-password
      required
    )
    button.btn.btn-danger.mt-5.px-5(
      type="button"
      @click="submitNewPW"
    ).
      {{ $t('forget.submit') }}
    button.btn.btn-link.text-white.btn-lg.mt-4(@click="openDialog")
      font-awesome-icon(:icon="['fas', 'globe-asia']").
      span.ml-2.
        {{ $t('currentLanguage') }}
    LangDialog(:visible='isOpen' @update:visible="closeDialog")
</template>

<script>
import LangDialog from '@/components/LangDialog/index.vue';

export default {
  components: {
    LangDialog,
  },
  props: ['query'],
  data() {
    return {
      auth: {
        email: '',
        sn: '',
      },
      images: {
        logo: require('@/assets/logo.png'),
      },
      isOpen: false,
      select: 'email',
      input: '',
    };
  },
  computed: {
    selectType() {
      return this.select === 'email' ? 'email' : 'text';
    },
  },
  methods: {
    async submitNewPW() {
      if (!this.$refs.form.checkValidity()) {
        return;
      }
      const payload = { pw: this.input, key: this.query };
      this.$store.dispatch('Reset/sendNewPW', payload);
      this.$message({
        showClose: true,
        message: this.$t('reset.sendSuccessMsg'),
      });
      setTimeout(() => this.$router.push('/login'), 2000);
    },
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
    checkKey() {
      this.$store.dispatch('Reset/getKey', this.query);
      setTimeout(() => {
        if (!this.$store.state.Reset.isGetKeySuccess) {
          this.$router.push(('/login'));
        }
      }, 1000);
    },
  },
  mounted() {
    this.checkKey();
  },
};
</script>

<style>
</style>
