export default class BasicConfig {
  /**
     *
     * @param {Number} pMax
     * @param {Number} pMin
     * @param {Number} delay
     * @param {Number} unit
     * @param {Number} pDryTime
     * @param {Number} pDry
     * @param {Number} autoReset
     * @param {Number} auto
     *
     * @param {Number} dt
     * @param {Number} ct
     * @param {Duration} to
     * @param {Duration} tf
     */
  constructor(
    pMax,
    pMin,
    delay,
    unit,
    pDryTime,
    pDry,
    autoReset,
    auto,

    dt,
    ct,
    to,
    tf,
  ) {
    this.pMax = pMax;
    this.pMin = pMin;
    this.delay = delay;
    this.unit = unit;
    this.pDryTime = pDryTime;
    this.pDry = pDry;
    this.autoReset = autoReset;
    this.auto = auto;

    this.dt = dt;
    this.ct = ct;
    this.to = to;
    this.tf = tf;
  }
}
