/* eslint-disable no-unused-vars */
/* eslint-disable no-console */

import dpc from '@/service/dpc';

localStorage.models = localStorage.models ?? '[]';

export default {
  namespaced: true,
  state: {
    mounted: [],
    mountYet: [],
    registed: [],
    models: JSON.parse(localStorage.models),
    alert: [],
  },
  getters: {
  },
  actions: {
    getMountYet: async ({ state, commit, rootState }, { id }) => {
      await dpc.getMountYet({ token: rootState.Auth.token }, { id })
        .then((data) => commit('setMountYet', (data.data ?? []).map((dpcData) => {
          const tmpDPC = dpcData;
          tmpDPC.module = state.models.find((model) => model.id === tmpDPC.modelId).name;
          return tmpDPC;
        })))
        .catch((err) => {
          commit('setMountYet', [
            {
              SN: 'A2005080064',
              UUID: 'AFDEA82886EBD616D4C6DA10FE68B389',
              Type: 'type 10',
              Module: 'DPC-25BT',
              BK: 'B389',
            },
            {
              SN: 'A2005080065',
              UUID: 'B92B61E441CC50D852F4019DF4198CAC',
              Type: 'type 44',
              Module: 'DPC-10BT',
              BK: '8CAC',
            },
            {
              SN: 'A2005080066',
              UUID: '7C3439BDB1CE52DAB832B417F9D96406',
              Type: 'type 13',
              Module: 'DPC-10ABT',
              BK: '6406',
            },
            {
              SN: 'A2005080067',
              UUID: 'CBD7570F08ADE5DD63EBC59DD52C32C7',
              Type: 'type 23',
              Module: 'DPC-10BT',
              BK: '32C7',
            },
            {
              SN: 'A2005080068',
              UUID: '6CC8A2D710C2A137551738B75DA4EDFE',
              Type: 'type 14',
              Module: 'DPC-10BT',
              BK: 'EDFE',
            },
            {
              SN: 'A2005080069',
              UUID: '19D229FEC6D166F627E1CDA9C64E20B2',
              Type: 'type 37',
              Module: 'DPC-16BT',
              BK: '20B2',
            },
            {
              SN: 'A2005080070',
              UUID: '914EA383E2EBAAA91F7E21912FF03942',
              Type: 'type 43',
              Module: 'DPC-10BT',
              BK: '3942',
            },
            {
              SN: 'A2005080071',
              UUID: '9F88D2B084FD04D4A8E8431F8C51FDE8',
              Type: 'type 1',
              Module: 'DPS-10BT',
              BK: 'FDE8',
            },
            {
              SN: 'A2005080072',
              UUID: '0BC32732A2EF980619E2C35442604ADF',
              Type: 'type 23',
              Module: 'DPS-10BT',
              BK: '4ADF',
            },
            {
              SN: 'A2005080073',
              UUID: 'D6C748B458B8B4D8A27E1AEB938732F0',
              Type: 'type 27',
              Module: 'DPC-25BT',
              BK: '32F0',
            },
            {
              SN: 'A2005080074',
              UUID: '71DC76B3757884A98988175B5DCDBA00',
              Type: 'type 12',
              Module: 'DPC-10ABT',
              BK: 'BA00',
            },
            {
              SN: 'A2005080075',
              UUID: 'E36F42ED7397ED87F6D0282C39704A58',
              Type: 'type 26',
              Module: 'DPC-16BT',
              BK: '4A58',
            },
            {
              SN: 'A2005080076',
              UUID: '648EA3120DC005188CBE47B542F4A59E',
              Type: 'type 21',
              Module: 'DPC-10BT',
              BK: 'A59E',
            },
            {
              SN: 'A2005080077',
              UUID: 'C83F40B38020DC82F25CCEB1129AC683',
              Type: 'type 24',
              Module: 'DPC-16BT',
              BK: 'C683',
            },
            {
              SN: 'A2005080078',
              UUID: '10810C3394B3D1001C12572A49A26544',
              Type: 'type 12',
              Module: 'DPS-10BT',
              BK: '6544',
            },
            {
              SN: 'A2005080079',
              UUID: '953588360F6508A292C2AD7D865A1F31',
              Type: 'type 40',
              Module: 'DPC-10BT',
              BK: '1F31',
            },
            {
              SN: 'A2005080080',
              UUID: '92AA80227D26238BB7734495B99ECB39',
              Type: 'type 49',
              Module: 'DPC-10ABT',
              BK: 'CB39',
            },
            {
              SN: 'A2005080081',
              UUID: 'CF47EC21E96B50EDBBC917BA2D09A8BF',
              Type: 'type 40',
              Module: 'DPC-10ABT',
              BK: 'A8BF',
            },
            {
              SN: 'A2005080082',
              UUID: '94F57E81751FADCE5245DE2E76E5AD7A',
              Type: 'type 3',
              Module: 'DPS-10BT',
              BK: 'AD7A',
            },
            {
              SN: 'A2005080083',
              UUID: '8C854576AEF0A6D39CE205BA6740ECE2',
              Type: 'type 46',
              Module: 'DPC-10ABT',
              BK: 'ECE2',
            },
            {
              SN: 'A2005080084',
              UUID: '4B306B0D876A49E2400156E2BE20C402',
              Type: 'type 30',
              Module: 'DPS-10BT',
              BK: 'C402',
            },
            {
              SN: 'A2005080085',
              UUID: '938B4740E8B3D564E9A51E30B565EBBF',
              Type: 'type 16',
              Module: 'DPC-10ABT',
              BK: 'EBBF',
            },
            {
              SN: 'A2005080086',
              UUID: '2E853C7E3D8113A9C03884E91A4A309E',
              Type: 'type 23',
              Module: 'DPC-25BT',
              BK: '309E',
            },
            {
              SN: 'A2005080087',
              UUID: 'ADCB6F2E4A5A3445595083FC64CBCCD9',
              Type: 'type 17',
              Module: 'DPS-10BT',
              BK: 'CCD9',
            },
            {
              SN: 'A2005080088',
              UUID: '5BF574D65E43BCBE6FA0A4AE7A99EB3D',
              Type: 'type 1',
              Module: 'DPS-10BT',
              BK: 'EB3D',
            },
            {
              SN: 'A2005080089',
              UUID: 'F69A0945D47565262F90A318C29954E6',
              Type: 'type 48',
              Module: 'DPC-16BT',
              BK: '54E6',
            },
            {
              SN: 'A2005080090',
              UUID: '052401A95B40D230CE8F0BC81A4AFD8C',
              Type: 'type 10',
              Module: 'DPC-10ABT',
              BK: 'FD8C',
            },
            {
              SN: 'A2005080091',
              UUID: 'F011210E4BC9553D98880275E213616C',
              Type: 'type 33',
              Module: 'DPC-10ABT',
              BK: '616C',
            },
            {
              SN: 'A2005080092',
              UUID: 'D79077BCDAD760807549F4D595ED2B3D',
              Type: 'type 6',
              Module: 'DPC-10ABT',
              BK: '2B3D',
            },
            {
              SN: 'A2005080093',
              UUID: '45BE1412E1F4764BB5951B076B03DBA4',
              Type: 'type 43',
              Module: 'DPC-25BT',
              BK: 'DBA4',
            },
            {
              SN: 'A2005080094',
              UUID: '85D5D89EE33D0F7FACC150705A2B86AE',
              Type: 'type 35',
              Module: 'DPC-25BT',
              BK: '86AE',
            },
            {
              SN: 'A2005080095',
              UUID: '645177B5EF22593391C2D83C5E2B7447',
              Type: 'type 40',
              Module: 'DPC-16BT',
              BK: '7447',
            },
            {
              SN: 'A2005080096',
              UUID: '690A9603976B8E411BB1F727F1805886',
              Type: 'type 17',
              Module: 'DPS-10BT',
              BK: '5886',
            },
            {
              SN: 'A2005080097',
              UUID: '7C17E7681B6B2B93BB9A627645535C02',
              Type: 'type 9',
              Module: 'DPS-10BT',
              BK: '5C02',
            },
            {
              SN: 'A2005080098',
              UUID: '5FE389B3F889E2727875E5E4621CB74C',
              Type: 'type 9',
              Module: 'DPC-10BT',
              BK: 'B74C',
            },
            {
              SN: 'A2005080099',
              UUID: '3063D927B4B7E244F96C62F45B07C258',
              Type: 'type 47',
              Module: 'DPC-10ABT',
              BK: 'C258',
            },
          ]);
        });
    },
    getMounted: async ({ state, commit, rootState }, { id }) => {
      await dpc.getMounted({ token: rootState.Auth.token }, { id })
        .then((data) => commit('setMounted', (data.data ?? []).map((dpcData) => {
          const tmpDPC = dpcData;
          tmpDPC.module = state.models.find((model) => model.id === tmpDPC.modelId).name;
          return tmpDPC;
        })))
        .catch((err) => {
          commit('setMounted', [
            {
              SN: 'A2005080000',
              UUID: '21C9EC296C1FB57FE638FA0EB4921D57',
              Type: 'type 27',
              Module: 'DPC-10ABT',
              BK: '1D57',
            },
            {
              SN: 'A2005080001',
              UUID: '85729A6172479382CE3205CF716C83CA',
              Type: 'type 48',
              Module: 'DPC-25BT',
              BK: '83CA',
            },
            {
              SN: 'A2005080002',
              UUID: 'B136A63FFEF69DC7DC6716F76F14A180',
              Type: 'type 10',
              Module: 'DPC-25BT',
              BK: 'A180',
            },
            {
              SN: 'A2005080003',
              UUID: 'A40DA8CD34745FB1B74FA52203E6572F',
              Type: 'type 34',
              Module: 'DPC-10BT',
              BK: '572F',
            },
            {
              SN: 'A2005080004',
              UUID: 'B5BF8784D0B31DBE75E5817C384EB374',
              Type: 'type 8',
              Module: 'DPC-10BT',
              BK: 'B374',
            },
            {
              SN: 'A2005080005',
              UUID: '589FF74074DC20268D66422FE87F8A87',
              Type: 'type 40',
              Module: 'DPC-10ABT',
              BK: '8A87',
            },
            {
              SN: 'A2005080006',
              UUID: '440CB6298C9018A8D323EA20ED0F5F8A',
              Type: 'type 33',
              Module: 'DPC-16BT',
              BK: '5F8A',
            },
            {
              SN: 'A2005080007',
              UUID: 'F321BC791976A1960D632AF979E178AF',
              Type: 'type 20',
              Module: 'DPC-10ABT',
              BK: '78AF',
            },
            {
              SN: 'A2005080008',
              UUID: '8859CC81C955B7DB0C5CFFEC320BDE7E',
              Type: 'type 10',
              Module: 'DPC-16BT',
              BK: 'DE7E',
            },
            {
              SN: 'A2005080009',
              UUID: '1D0FA61286B1F10766941DA10ED44B05',
              Type: 'type 0',
              Module: 'DPC-10BT',
              BK: '4B05',
            },
            {
              SN: 'A2005080010',
              UUID: 'A7760EE39F2FE5260445929D6AE4D7C0',
              Type: 'type 20',
              Module: 'DPC-10ABT',
              BK: 'D7C0',
            },
            {
              SN: 'A2005080011',
              UUID: '49AB4264B8FD52DE14146292521D3E04',
              Type: 'type 7',
              Module: 'DPC-10ABT',
              BK: '3E04',
            },
            {
              SN: 'A2005080012',
              UUID: 'E6C44BA567E0DDD36058FC00CB970209',
              Type: 'type 3',
              Module: 'DPC-25BT',
              BK: '0209',
            },
            {
              SN: 'A2005080013',
              UUID: '2B4EC2522237DF63C78BE7EA521BB665',
              Type: 'type 11',
              Module: 'DPC-10BT',
              BK: 'B665',
            },
            {
              SN: 'A2005080014',
              UUID: 'B1BB7059840C82D756FA1EBBDF0B6165',
              Type: 'type 4',
              Module: 'DPC-10ABT',
              BK: '6165',
            },
            {
              SN: 'A2005080015',
              UUID: '5D8FD2B94CB2D70E726FD7B9DEE09A89',
              Type: 'type 24',
              Module: 'DPC-10BT',
              BK: '9A89',
            },
            {
              SN: 'A2005080016',
              UUID: '6D661F6C3E484FC80DCE87F53C8D56E9',
              Type: 'type 23',
              Module: 'DPC-25BT',
              BK: '56E9',
            },
            {
              SN: 'A2005080017',
              UUID: 'EB49E90AA80E219462AE60ABF1EC0D65',
              Type: 'type 30',
              Module: 'DPS-10BT',
              BK: '0D65',
            },
            {
              SN: 'A2005080018',
              UUID: 'D995B9585C195C1F357D64AC6378C79F',
              Type: 'type 1',
              Module: 'DPC-10ABT',
              BK: 'C79F',
            },
            {
              SN: 'A2005080019',
              UUID: '0B29C97D83C59FD921FD2584A7F9EAD3',
              Type: 'type 21',
              Module: 'DPC-10BT',
              BK: 'EAD3',
            },
            {
              SN: 'A2005080020',
              UUID: '734CD0CFBA64A5D260E5E4A6D59C3070',
              Type: 'type 14',
              Module: 'DPS-10BT',
              BK: '3070',
            },
            {
              SN: 'A2005080021',
              UUID: '79D568F7D1531A706B2D3EBBC38E399C',
              Type: 'type 6',
              Module: 'DPC-10BT',
              BK: '399C',
            },
            {
              SN: 'A2005080022',
              UUID: '90F67B78310EB8FBDB4D0676D304D686',
              Type: 'type 21',
              Module: 'DPC-10BT',
              BK: 'D686',
            },
            {
              SN: 'A2005080023',
              UUID: 'F8891DAC6F2382B4FCAD3E1C950EBE11',
              Type: 'type 45',
              Module: 'DPC-10BT',
              BK: 'BE11',
            },
            {
              SN: 'A2005080024',
              UUID: '9F8D2DD01F3266E6FA9EB28FE738E466',
              Type: 'type 21',
              Module: 'DPC-16BT',
              BK: 'E466',
            },
            {
              SN: 'A2005080025',
              UUID: 'C55C167C43B36F272E47156987F5446A',
              Type: 'type 12',
              Module: 'DPC-10ABT',
              BK: '446A',
            },
          ]);
        });
    },
    getRegisted: async ({ state, commit, rootState }, { id }) => {
      await dpc.getRegisted({ token: rootState.Auth.token }, { id })
        .then((data) => commit('setRegisted', (data.data ?? []).map((dpcData) => {
          const tmpDPC = dpcData;
          tmpDPC.module = state.models.find((model) => model.id === tmpDPC.modelId).name;
          return tmpDPC;
        })))
        .catch((err) => {
          commit('setRegisted', [
            {
              SN: 'A2005080026',
              UUID: '65F6C35CA26A1B5F247DB616E455DAB0',
              Type: 'type 23',
              Module: 'DPC-16BT',
              BK: 'DAB0',
            },
            {
              SN: 'A2005080027',
              UUID: 'D6B0DA03B6B3389DA06ECCFD60385D3B',
              Type: 'type 19',
              Module: 'DPC-16BT',
              BK: '5D3B',
            },
            {
              SN: 'A2005080028',
              UUID: '0A563F8F27496CB86D508356855A9C96',
              Type: 'type 12',
              Module: 'DPC-16BT',
              BK: '9C96',
            },
            {
              SN: 'A2005080029',
              UUID: 'B50DE873742CBBC45374A34A7A844C45',
              Type: 'type 16',
              Module: 'DPC-10ABT',
              BK: '4C45',
            },
            {
              SN: 'A2005080030',
              UUID: '9102E677481C7F80EABD090508F4B6E0',
              Type: 'type 40',
              Module: 'DPC-10ABT',
              BK: 'B6E0',
            },
            {
              SN: 'A2005080031',
              UUID: 'E0DE1E627635BC120DD1217D6031A486',
              Type: 'type 41',
              Module: 'DPC-10BT',
              BK: 'A486',
            },
            {
              SN: 'A2005080032',
              UUID: '4EBBEC6EC311786CED1030BB1C722DA2',
              Type: 'type 21',
              Module: 'DPC-25BT',
              BK: '2DA2',
            },
            {
              SN: 'A2005080033',
              UUID: 'EFECB9C83B982B6D81101825F7FD658D',
              Type: 'type 19',
              Module: 'DPC-16BT',
              BK: '658D',
            },
            {
              SN: 'A2005080034',
              UUID: 'B4EE048D297A6834E41E568670C87037',
              Type: 'type 3',
              Module: 'DPC-10ABT',
              BK: '7037',
            },
            {
              SN: 'A2005080035',
              UUID: '67A0B84355FE415699DA71259A071300',
              Type: 'type 42',
              Module: 'DPC-10BT',
              BK: '1300',
            },
            {
              SN: 'A2005080036',
              UUID: 'F5C4226A527BEFF7C7637546B548EACC',
              Type: 'type 30',
              Module: 'DPC-10ABT',
              BK: 'EACC',
            },
            {
              SN: 'A2005080037',
              UUID: '86403D8EA28874A49BC0870EEF9D0769',
              Type: 'type 37',
              Module: 'DPC-16BT',
              BK: '0769',
            },
            {
              SN: 'A2005080038',
              UUID: 'FF9C8243D9503E3210A5B6B27F471652',
              Type: 'type 14',
              Module: 'DPC-16BT',
              BK: '1652',
            },
            {
              SN: 'A2005080039',
              UUID: '0FAD9EBEEF348A7500E82588DBDC92DE',
              Type: 'type 15',
              Module: 'DPC-10ABT',
              BK: '92DE',
            },
            {
              SN: 'A2005080040',
              UUID: '49274554BAAE3D4C1211229B6ACBD9FB',
              Type: 'type 41',
              Module: 'DPC-25BT',
              BK: 'D9FB',
            },
            {
              SN: 'A2005080041',
              UUID: '9ACEA14AA262CD9E24AB51E783D9D8F7',
              Type: 'type 45',
              Module: 'DPC-10ABT',
              BK: 'D8F7',
            },
            {
              SN: 'A2005080042',
              UUID: 'CA0FE003A2B185F59921E44B0C679AA7',
              Type: 'type 44',
              Module: 'DPC-10ABT',
              BK: '9AA7',
            },
            {
              SN: 'A2005080043',
              UUID: 'E719C6F727EEFB8C45199D5EA415B72C',
              Type: 'type 2',
              Module: 'DPC-10ABT',
              BK: 'B72C',
            },
            {
              SN: 'A2005080044',
              UUID: '6267A3F130D7E05F335CF62CE22A3624',
              Type: 'type 31',
              Module: 'DPC-25BT',
              BK: '3624',
            },
            {
              SN: 'A2005080045',
              UUID: '2DEB1DB77F30A729C602C9D45749BC7E',
              Type: 'type 16',
              Module: 'DPC-16BT',
              BK: 'BC7E',
            },
            {
              SN: 'A2005080046',
              UUID: '02F68F14BF19FC5C17758FBC51B4FC54',
              Type: 'type 22',
              Module: 'DPC-10BT',
              BK: 'FC54',
            },
            {
              SN: 'A2005080047',
              UUID: 'B49A4C6DA020491BB237E8F6659AF40A',
              Type: 'type 3',
              Module: 'DPC-16BT',
              BK: 'F40A',
            },
            {
              SN: 'A2005080048',
              UUID: 'B6948F7B5978525D6478D52C5A9276B0',
              Type: 'type 48',
              Module: 'DPC-16BT',
              BK: '76B0',
            },
            {
              SN: 'A2005080049',
              UUID: 'B06B2BC464B81330D62618E506301399',
              Type: 'type 6',
              Module: 'DPS-10BT',
              BK: '1399',
            },
            {
              SN: 'A2005080050',
              UUID: '0704B3DD9A040E08DF51A54FDD5B4B1E',
              Type: 'type 22',
              Module: 'DPS-10BT',
              BK: '4B1E',
            },
            {
              SN: 'A2005080051',
              UUID: '54ED94EE63625390535C8BF4CDA51475',
              Type: 'type 25',
              Module: 'DPC-16BT',
              BK: '1475',
            },
            {
              SN: 'A2005080052',
              UUID: '017A504C8FDB7426DDAFA8A7210B67DD',
              Type: 'type 32',
              Module: 'DPC-16BT',
              BK: '67DD',
            },
            {
              SN: 'A2005080053',
              UUID: '17355FA564346F16872848128BC15B6B',
              Type: 'type 7',
              Module: 'DPC-10BT',
              BK: '5B6B',
            },
            {
              SN: 'A2005080054',
              UUID: 'FFF1ABF7C421A652859E9BDD63D88D31',
              Type: 'type 8',
              Module: 'DPC-10ABT',
              BK: '8D31',
            },
            {
              SN: 'A2005080055',
              UUID: 'FC94A5874AF5ACECA84662A6E6E5C8C2',
              Type: 'type 40',
              Module: 'DPC-10BT',
              BK: 'C8C2',
            },
            {
              SN: 'A2005080056',
              UUID: 'EC29B3EE036644D7473EC91307857F0B',
              Type: 'type 3',
              Module: 'DPC-10BT',
              BK: '7F0B',
            },
            {
              SN: 'A2005080057',
              UUID: '51A967E18EBCB7721D10AD4C04C07226',
              Type: 'type 15',
              Module: 'DPC-25BT',
              BK: '7226',
            },
            {
              SN: 'A2005080058',
              UUID: '6318D5BC4E94B8709424925BBE1C7395',
              Type: 'type 31',
              Module: 'DPC-10BT',
              BK: '7395',
            },
            {
              SN: 'A2005080059',
              UUID: 'DD9D6AA07F1E1105A223C04CB4FDB617',
              Type: 'type 13',
              Module: 'DPC-16BT',
              BK: 'B617',
            },
            {
              SN: 'A2005080060',
              UUID: '44D6A51192C9B67C1D4A272F1289E9C9',
              Type: 'type 46',
              Module: 'DPC-25BT',
              BK: 'E9C9',
            },
            {
              SN: 'A2005080061',
              UUID: '0F76CC8B7F1C7FFE9A66EE6D3DBD3748',
              Type: 'type 48',
              Module: 'DPC-10ABT',
              BK: '3748',
            },
            {
              SN: 'A2005080062',
              UUID: '5806B7A27684751C0EAA0DB410DFEA4A',
              Type: 'type 8',
              Module: 'DPC-16BT',
              BK: 'EA4A',
            },
            {
              SN: 'A2005080063',
              UUID: 'EF7FCEFD2F2AC545F3018A5B3FF576CD',
              Type: 'type 40',
              Module: 'DPC-16BT',
              BK: '76CD',
            },
          ]);
        });
    },
    importDPCs: async ({
      state, commit, rootState, dispatch,
    }, { dpcs }) => {
      await dpc.importDPCs({ token: rootState.Auth.token, dpcs })
        .then((data) => dispatch('getReadyToRegistDPCs', { token: rootState.Auth.token }));
    },
    getAllModels: async ({ commit, rootState, dispatch }) => {
      await dpc.getAllModels({ token: rootState.Auth.token })
        .then((data) => commit('setModels', data.data),
          (err) => commit('setModels', [
            {
              name: 'DPCII-10A',
              id: 1,
            },
            {
              name: 'DPCII-16A',
              id: 2,
            },
            {
              name: 'DPCII-25A',
              id: 3,
            },
          ]));
    },
    getAllAlert: async ({ commit, rootState, dispatch }) => {
      await dpc.getAllAlert({ token: rootState.Auth.token, sn: rootState.WS.sn })
        .then((data) => commit('setAlert', [
          {
            createAt: '2020-06-11T00:00:00',
            createBy: 'yourmonmon',
            alarmCode: 224,
            alarmString: 'E0',
            uuid: 'uuid',
            serialNumber: 'sn',
          },
        ]), (err) => console.log('nothing.'))
        .catch((err) => commit('setAlert', [
          {
            createAt: '2020-06-11T00:00:00',
            createBy: 'yourmonmon',
            alarmCode: 224,
            alarmString: 'E0',
            uuid: 'uuid',
            serialNumber: 'sn',
          },
        ]));
    },
    assignDPC: async ({ commit, rootState, dispatch }, { data }) => {
      await dpc.assignDPC({ token: rootState.Auth.token, data })
        .catch((err) => console.log('nothing.'));
    },
  },

  mutations: {
    setMountYet: (state, value) => { state.mountYet = value; },
    setMounted: (state, value) => { state.mounted = value; },
    setRegisted: (state, value) => { state.registed = value; },
    setModels: (state, value) => {
      state.models = value;
      localStorage.models = JSON.stringify(value);
    },
    setAlert: (state, value) => { state.alert = value; },
  },
};
