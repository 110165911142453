import Command from '../../core/Command';
import Status from '../../core/Status';
import BasicResponse from '../../core/BasicResponse';
import RealTimePressure from '../../core/RealTimePressure';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);

/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function status(obj) {
  return new Status(obj.command, obj.data[0]);
}

/**
 * @param {OriginalModel} obj
 * @returns {BasicResponse | Status}
 */
function response(obj) {
  try {
    const data = {
      message: parseInt(hex2dec(obj.data[0].toLocaleLowerCase()), 10),
      status: {
        '00': 0, // 系統停止運轉(無燈)
        EE: 1, // 缺水乾運轉(紅燈亮)
        E0: 2, // 壓力過大(紅燈亮)
        F0: 3, // 系統運轉中且PUMP Off (綠色燈亮)
        F8: 4, // 系統Idle (閃綠色燈一秒一閃)
        FF: 5, // 系統運轉中,且PUMP ON(橙色燈亮)
      }[obj.data[1].toLocaleUpperCase()],
      pressure: parseInt(hex2dec(`${obj.data[2]}${obj.data[3]}`.toLocaleLowerCase()), 10),
      unit: {
        '01': 0, // bar value/100
        '02': 1, // kgf/cm2 value/100
        '04': 2, // psi value/10
      }[obj.data[4].toLocaleUpperCase()],
      lock: {
        '0C': true,
        '0D': false,
      }[obj.data[5].toLocaleUpperCase()],
    };

    const trans = [100, 100, 10];

    return new BasicResponse(
      obj.command,
      new RealTimePressure(
        data.message,
        data.status,
        data.pressure / trans[data.unit],
        data.unit,
        data.lock,
      ),
    );
  } catch (e) {
    return status(obj);
  }
}

export default {
  [Command.START_REAL_TIME_PRESSURE]: response,
};
