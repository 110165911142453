<template lang="pug">
form.form(ref="form")
  .thumbnail
    img(:src="images.inspect")
  .login-form
    input(type="text" :placeholder="$t('service.login.serviceCode')" v-model="channelKey" required)
    button(@click="login" type="submit") {{ $t('service.login.login') }}
</template>

<script>
// import dpc from '@/utils/DPC';
// import command from '@/utils/DPC/core/Command';
import remote from '@/service/remote';

export default {
  data() {
    return {
      channelKey: '',
      images: {
        inspect: require('@/assets/inspect.png'),
      },
    };
  },
  methods: {
    async login() {
      if (!this.$refs.form.checkValidity()) {
        return;
      }
      const info = await remote.getRemoteINFO({
        token: this.$store.state.Auth.token,
        channelKey: this.channelKey,
      });
      this.$store.commit('WS/init', info.data);
      setTimeout(() => { this.$router.push('/service/remote/setting'); }, 1000);
    },
  },
};
</script>

<style scpoed>
    /* Form */
    .form {
        position: relative;
        z-index: 1;
        background: #FFFFFF;
        max-width: 300px;
        height: 100%;
        margin: 50px auto 0;
        padding: 30px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        text-align: center;
    }

    .form .thumbnail {
        /* background: #EF3B3A; */
        width: 150px;
        height: 200px;
        margin: auto;
        padding: 0 0 50px 0;
        border-top-left-radius: 100%;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
        box-sizing: border-box;
    }

    .form .thumbnail img {
        display: block;
        width: 150px;
    }

    .form input {
        outline: 0;
        background: #f2f2f2;
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        box-sizing: border-box;
        font-size: 14px;
    }

    .form button {
        outline: 0;
        background: #EF3B3A;
        width: 100%;
        border: 0;
        padding: 15px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        color: #FFFFFF;
        font-size: 14px;
        -webkit-transition: all 0.3 ease;
        transition: all 0.3 ease;
        cursor: pointer;
    }

    .form .message {
        margin: 15px 0 0;
        color: #b3b3b3;
        font-size: 12px;
    }

    .form .message a {
        color: #EF3B3A;
        text-decoration: none;
    }

    .form .register-form {
        display: none;
    }

    input::placeholder {
        color: #909399;
    }
</style>
