import Command from '../../core/Command';
import OriginalModel from '../../core/OriginalModel';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);
function padLeft(str, length) {
  if (str.length >= length) return str;
  return padLeft(`0${str}`, length);
}

/**
 * @param {BasicConfig} data
 * @returns {String[]}
 */
function adapter(data) {
  const pMax = padLeft(dec2hex(data.pMax.toString()), 4);
  const pMin = padLeft(dec2hex(data.pMin.toString()), 4);
  const delay = padLeft(dec2hex((data.delay).toString()), 2);

  const unit = {
    0: '01', // bar
    1: '02', // kgf/cm2
    2: '04', // psi
  }[data.unit];

  const pDryTime = padLeft(dec2hex((data.pDryTime).toString()), 2);
  const pDry = padLeft(dec2hex(data.pDry.toString()), 4);
  const autoReset = padLeft(dec2hex(data.autoReset.toString()), 4);
  const auto = padLeft(dec2hex((data.auto).toString()), 2);

  // Advanced config
  const dt = padLeft(dec2hex((data.dt).toString()), 2);
  const ct = padLeft(dec2hex((data.ct).toString()), 2);
  const toH = dec2hex((data.to.hours()).toString());
  const toM = dec2hex(data.to.minutes().toString()[0]);
  const to = `${toH}${toM}`;
  const tfH = dec2hex((data.tf.hours()).toString());
  const tfM = dec2hex(data.tf.minutes().toString()[0]);
  const tf = `${tfH}${tfM}`;

  return [pMax, pMin, delay, unit, pDryTime, pDry, autoReset, auto, dt, ct, to, tf];
}
export default {
  [Command.UPDATE_BASIC_CONFIG]: (data) => new OriginalModel('55', Command.UPDATE_BASIC_CONFIG, '', adapter(data), '', 'AA'),
};
