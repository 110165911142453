<template lang="pug">
el-dialog(
  :title="$t('systemManagement.del.title')"
  :visible.sync="visible"
  :before-close="closeDialog"
  width="60%"
  center
)
  h1 {{ $t('systemManagement.del.confirmTitle') }}
  span.dialog-footer(slot="footer")
    el-button(@click="closeDialog") {{ $t('systemManagement.del.cancel') }}
    el-button(type="primary" @click="confirm") {{ $t('systemManagement.del.confirm') }}
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    async confirm() {
      await this.$store.dispatch('System/removeNode', { id: this.target });
      this.$emit('update:visible', false);
      this.$emit('update:target', 0);
    },
    closeDialog() {
      this.$emit('update:visible', false);
      this.$emit('update:target', 0);
    },
  },
};
</script>
