<template lang="pug">
el-dialog(
  :title="$t('systemManagement.edit.title')"
  :visible.sync="visible"
  width="60%"
  :before-close="closeDialog"
  center
  )
  el-form(:model="data")
    el-form-item(:label="$t('systemManagement.edit.nodeName')" prop="nodeTitle" label-width="180px")
      el-input(v-model="data.info.title" :placeholder="$t('systemManagement.edit.nodeNameInput')")
    el-form-item(
      :label="$t('systemManagement.edit.companyName')"
      prop="nodeCompany"
      label-width="180px"
      )
      el-input(
        v-model="data.info.company"
        :placeholder="$t('systemManagement.edit.companyNameInput')"
        )
    el-form-item(
      :label="$t('systemManagement.edit.contact')" prop="nodeContact" label-width="180px")
      el-input(v-model="data.info.contact" :placeholder="$t('systemManagement.edit.contactInput')")
    el-form-item(
      :label="$t('systemManagement.edit.software')"
      prop="nodeContact"
      label-width="180px"
      )
      el-select.full-width(
        v-model="data.info.socialId"
        placeholder
        @change="onSelect"
      )
        el-option(:label="$t('systemManagement.edit.softwareInput')" :value="0" :key="0")
        el-option(v-for="item in social" :key="item.id" :value="item.id" :label="item.name")
    el-form-item(
      :label="$t('systemManagement.edit.account')" prop="nodeContact" label-width="180px")
      el-input(
        v-model="data.info.socialAccount"
        :placeholder="$t('systemManagement.edit.accountInput')"
        :disabled="data.info.socialId === 0"
      )
    el-form-item(:label="$t('systemManagement.edit.phone')" prop="nodePhone" label-width="180px")
      el-input(v-model="data.info.phone" :placeholder="$t('systemManagement.edit.phoneInput')")
    el-form-item(:label="$t('systemManagement.edit.email')" prop="nodeEmail" label-width="180px")
      el-input(v-model="data.info.email" :placeholder="$t('systemManagement.edit.emailInput')")
    el-form-item(
      :label="$t('systemManagement.edit.address')" prop="nodeAddress" label-width="180px")
      el-input(v-model="data.info.address" :placeholder="$t('systemManagement.edit.addressInput')")
    el-form-item(:label="$t('systemManagement.edit.authority')" label-width="180px")
      el-checkbox(:label="$t('systemManagement.edit.remote')" v-model="data.purview.remote")
      el-checkbox(:label="$t('systemManagement.edit.push')" v-model="data.purview.notify")
      el-checkbox(:label="$t('systemManagement.edit.node')" v-model="data.purview.node")
      el-checkbox(:label="$t('systemManagement.edit.businessCard')" v-model="data.purview.card")
  el-row(type="flex" justify="center")
    el-button(@click="closeDialog") {{ $t('systemManagement.edit.cancel') }}
    el-button(
      type="primary"
      @click="confirm"
      :disabled="data.info.socialId !== 0 && data.info.socialAccount === ''"
    ) {{ $t('systemManagement.edit.confirm') }}
</template>

<script>
import {
  mapState,
  // mapActions
} from 'vuex';

export default {
  data: () => ({
    activeName: 'first',
    select: 0,
  }),
  computed: mapState({
    social: (state) => state.Social.social,
    tree: (state) => state.System.tree,
    data: (state) => state.System.detail,
  }),
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelect(data) {
      if (data === 0) {
        this.data.info.socialAccount = '';
      }
    },
    confirm() {
      this.activeName = 'first';

      const purview = Object.keys(this.data.purview).filter((item) => this.data.purview[item]);
      const purviewId = purview
        .map((item) => this.$store.state.Permission.permission
          .find((per) => per.systemName === item).id);

      this.$store.dispatch('System/updateNodeDetail', {
        id: this.data.id,
        detail: {
          lastName: this.data.info.title,
          phone: this.data.info.phone,
          streetAddress: this.data.info.address,
          vendorPermissionIds: purviewId,
          contact: this.data.info.contact,
          company: this.data.info.company,
          socialId: this.data.info.socialId,
          socialAccount: this.data.info.socialAccount,
          email: this.data.info.email,
        },
      })
        .then(() => this.$store.dispatch('System/getTree'))
        .then(() => this.$emit('update:visible', false))
        .catch((err) => console.log(err));
      this.$emit('confirm');
    },
    closeDialog() {
      this.$emit('update:visible', false);
    },
  },
};
</script>

<style scoped>
    .full-width {
        width: 100%;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
