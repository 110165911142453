import RegisterSuccessResponse from './RegisterRequest';
import DPCResponse from './DPCResponse';
import ErrorResponse from './ErrorResponse';
import ServerResponse from './ServerResponse';
import OriginalModel from './OriginalModel';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);

/**
 *
 * @param {String} response
 * @returns {ServerResponse}
 */
export function serverResponseToObj(response) {
  const json = JSON.parse(response);

  const responseData = {
    1: (data) => new RegisterSuccessResponse(data),
    2: (data) => new DPCResponse(data.SerialNumber, data.HexString),
    3: (data) => new ErrorResponse(data.ErrorType, data.ErrorString),
  }[json.MessageType](json.Data);

  return new ServerResponse(json.MessageType, responseData);
}

/**
 *
 * @param {String} response
 * @returns {Boolean}
 */
export function isServerResponse(response) {
  const json = JSON.parse(response);
  const data = json.Data;
  return data !== undefined;
}

/**
 * @param {String} data
 */
export function firstTransform(data) {
  const dataStartIndex = 0;
  const dataCommandStartIndex = 2;
  const dataLengthStart = 4;
  const dataCheckStartIndex = data.length - 4;
  const dataEndStartIndex = data.length - 2;

  // 資料開頭
  const start = `${data[dataStartIndex]}${data[dataStartIndex + 1]}`;

  // 指令
  const command = `${data[dataCommandStartIndex]}${data[dataCommandStartIndex + 1]}`;

  // 資料長度
  const length = `${data[dataLengthStart]}${data[dataLengthStart + 1]}`;

  // 檢查碼
  const check = `${data[dataCheckStartIndex]}${data[dataCheckStartIndex + 1]}`;

  // 資料結尾
  const end = `${data[dataEndStartIndex]}${data[dataEndStartIndex + 1]}`;

  const dataArr = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < hex2dec(length.toLocaleLowerCase()); i++) {
    // eslint-disable-next-line no-shadow
    const start = dataLengthStart + ((i + 1) * 2);
    dataArr.push(`${data[start]}${data[start + 1]}`);
  }

  return new OriginalModel(start, command, length, dataArr, check, end);
}
