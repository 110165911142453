<template lang="pug">
el-dialog(
  :title="$t('systemManagement.add.title')"
  :visible.sync="visible"
  width="60%"
  :before-close="closeDialog"
  center
)
  el-form(:model="data")
    el-form-item(:label="$t('systemManagement.add.nodeName')" prop="nodeName" label-width="180px")
      el-input(v-model="data.name" :placeholder="$t('systemManagement.add.nodeNameInput')")
    el-form-item(:label="$t('systemManagement.add.account')" prop="account" label-width="180px")
      el-input(
        v-model="data.account"
        :placeholder="$t('systemManagement.add.accountInput')"
        @input="checkAccount"
        )
    el-form-item(:label="$t('systemManagement.add.password')" prop="password" label-width="180px")
      el-input(
        type="password"
        v-model="data.password"
        :placeholder="$t('systemManagement.add.passwordInput')"
        )
    el-form-item(:label="$t('systemManagement.add.authority')" label-width="180px")
      el-checkbox(:label="$t('systemManagement.add.remote')" v-model="data.purview.remote")
      el-checkbox(:label="$t('systemManagement.add.push')" v-model="data.purview.notify")
      el-checkbox(:label="$t('systemManagement.add.node')" v-model="data.purview.node")
      el-checkbox(:label="$t('systemManagement.add.businessCard')" v-model="data.purview.card")
  span.dialog-footer(slot="footer")
    el-button(@click="closeDialog") {{ $t('systemManagement.add.cancel') }}
    el-button(
      type="primary"
      @click="confirm"
      :disabled="checkData"
      ) {{ $t('systemManagement.add.confirm') }}
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    target: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isExist: false,
      data: {
        name: '',
        account: '',
        password: '',
        purview: {
          remote: false,
          notify: false,
          node: false,
          card: true,
        },
      },
    };
  },
  computed: {
    checkData() {
      return this.data.name === '' || this.data.account === '' || this.data.password === '' || this.isExist;
    },
  },
  methods: {
    async checkAccount() {
      await this.$store.dispatch('System/checkAccountExist', { account: this.data.account })
        .then((data) => {
          console.log('isexist', data);
          this.isExist = data;
        });
    },
    async confirm() {
      const purview = Object.keys(this.data.purview).filter((item) => this.data.purview[item]);
      const purviewId = purview
        .map((item) => this.$store.state.Permission.permission
          .find((per) => per.systemName === item).id);

      await this.$store.dispatch('System/addNode', {
        data: {
          UsernameOrEmail: this.data.account,
          LastName: this.data.name,
          Password: this.data.password,
          ParentId: this.target,
          VendorPermissionIds: purviewId,
        },
      })
        .then(() => this.$store.dispatch('System/getTree'));

      this.$emit('update:visible', false);
      this.$emit('update:target', 0);
      this.data = {
        name: '',
        account: '',
        password: '',
        purview: {
          remote: false,
          notify: false,
          node: false,
          card: true,
        },
      };
    },
    closeDialog() {
      this.$emit('update:visible', false);
      this.$emit('update:target', 0);
      this.data = {
        name: '',
        account: '',
        password: '',
        purview: {
          remote: false,
          notify: false,
          node: false,
          card: true,
        },
      };
    },
  },
};
</script>
