export default class AdvancedConfig {
  /**
     *
     * @param {Number} dt
     * @param {Number} ct
     * @param {Duration} to
     * @param {Duration} tf
     */
  constructor(
    dt,
    ct,
    to,
    tf,
  ) {
    this.dt = dt;
    this.ct = ct;
    this.to = to;
    this.tf = tf;
  }
}
