export default class BasicResponse {
  /**
     * @param {String} command
     */
  constructor(command, data) {
    this.command = {
      '00': 'START_REAL_TIME_PRESSURE',
      FF: 'STOP_REAL_TIME_PRESSURE',
      '01': 'GET_BASIC_CONFIG',
      '02': 'GET_ADVANCED_CONFIG',
      '0C': 'LOCK_DPC',
      '0D': 'UNLOCK_DPC',
      11: 'UPDATE_BASIC_CONFIG',
      12: 'UPDATE_ADVANCED_CONFIG',
      20: 'GET_DPC_INFO',
      F0: 'STOP_PUMP',
      FA: 'START_PUMP',
      30: 'HEAR_BEAT',
      77: 'RESET_CONFIG',
    }[command];

    this.data = data;
  }
}
