export default class Command {
  static RESET_CONFIG = '77';

  static START_REAL_TIME_PRESSURE = '00';

  static STOP_REAL_TIME_PRESSURE = '7F';

  static GET_BASIC_CONFIG = '01';

  static GET_ADVANCED_CONFIG = '02';

  static LOCK_DPC = '0C';

  static UNLOCK_DPC = '0D';

  static UPDATE_BASIC_CONFIG = '11';

  static UPDATE_ADVANCED_CONFIG = '12';

  static STOP_PUMP = '70';

  static START_PUMP = '7A';

  static REGISTER_TOKEN = 'XX';

  static HEAR_BEAT = '30';
}
