export default class ServerResponse {
  /**
     *
     * @param {Number} MessageType
     * @param {DPCResponse | RegisterSuccessResponse | ErrorResponse} Data
     */
  constructor(MessageType, Data) {
    this.MessageType = MessageType;
    this.Data = Data;
  }
}
