<template lang="pug">
    div.text-center.loginIndex
        img.img-fluid(:src="images.logo" alt)
</template>

<script>

export default {
  data() {
    return {
      images: {
        logo: require('@/assets/logo.png'),
      },
    };
  },
};
</script>

<style scoped>
    .loginIndex{
        position: absolute;
        left:0;
        top:0;
        width:100vw;
        height:100vh;
        z-index:-1;
        background-color:rgbA(5, 61, 84,1);
}
    .loginIndex img{
        margin-top:20vh;
        margin-left:10vw;
    }
    .el-breadcrumb__inner{
        color:#FFFFFF!important;
    }
</style>
