<template lang="pug">
el-row(style="margin: 0px 0px 0px 0px")
  add-node-dialog(:visible.sync="visible.add" :target.sync="target.add")
  edit-node-dialog(:visible.sync="visible.edit")
  del-node-dialog(:visible.sync="visible.del" :target.sync="target.del")
  el-row(type="flex" justify="center")
    h1 {{targetNode.label}}
  el-row(type="flex" justify="center")
    el-col
      el-tabs(ref="tab" v-model="active" type="card")
        el-tab-pane(:label="$t('systemManagement.mountedYetDpc')" name="mounted-yet-dpc")
          template(slot="label")
          register-dPC-table(ref="mounted-yet-dpc" name="mountYet")
        el-tab-pane(:label="$t('systemManagement.mountedDpc')" name="mounted-dpc")
          template(slot="label")
          register-dPC-table(ref="mounted-dpc" name="mounted")
        el-tab-pane(:label="$t('systemManagement.registedDpc')" name="registed-dpc")
          template(slot="label")
          register-dPC-table(ref="registed-dpc" name="registed")
  root-tree(
    ref="root-tree"
    :color="colorStatus"
    :addDPCList="filterData"
    :targetId="targetNode.id"
    @editNode="openEditDialog"
    @delNode="openDeleteNodeDialog"
    @addNode="openAddNodeForm"
  )
</template>

<script>
import {
  mapState,
  // mapActions
} from 'vuex';
import RootTree from './components/rootTree/index.vue';
import AddNodeDialog from './components/addNodeDialog/index.vue';
import EditNodeDialog from './components/editNodeDialog/index.vue';
import DelNodeDialog from './components/delNodeDialog/index.vue';
import RegisterDPCTable from './components/registerDPCTable/index.vue';

export default {
  components: {
    RootTree,
    AddNodeDialog,
    EditNodeDialog,
    DelNodeDialog,
    RegisterDPCTable,
  },
  data() {
    return {
      isMounted: false,
      active: 'mounted-yet-dpc',
      target: {
        add: 0,
        del: 0,
      },
      tableData: [],
      num: 0,
      visible: {
        del: false,
        add: false,
        edit: false,
        upload: false,
      },
      selectType: [],
      checkList: [],
      selectDate: '',
      readyToInsertToDBDPCs: [],
      delNode: {},
      addNodeData: {
        name: '',
        parent: {},
        purview: {
          remote: false,
          notify: false,
          node: false,
          card: true,
        },
      },
    };
  },
  watch: {
    async targetNode() {
      await this.$store.dispatch('DPC/getMountYet', { id: this.targetNode.id });
      await this.$store.dispatch('DPC/getMounted', { id: this.targetNode.id });
      await this.$store.dispatch('DPC/getRegisted', { id: this.targetNode.id });
    },
  },
  computed: mapState({
    mountCount() {
      let data = 0;
      if (this.isMounted) { data = this.$refs['mounted-dpc'].data.length; }
      return data;
    },
    mountedYetCount() {
      let data = 0;
      if (this.isMounted) { data = this.$refs['mounted-yet-dpc'].data.length; }
      return data;
    },
    registedCount() {
      let data = 0;
      if (this.isMounted) { data = this.$refs['registed-dpc'].data.length; }
      return data;
    },
    filterData() {
      let data = [];
      if (this.isMounted) { data = this.$refs[this.$refs.tab.value].selectedData; }
      return data;
    },
    targetNode() {
      let data = [];
      if (this.isMounted) { data = this.$refs['root-tree'].target; }

      return data;
    },
    colorStatus() {
      let data = 0;
      if (this.isMounted) {
        data = {
          'mounted-yet-dpc': 0, // 紅
          'mounted-dpc': 1, // 藍
          'registed-dpc': 2, // 綠
        }[this.$refs.tab.value];
      }

      return data;
    },
  }),
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    async editNode() {
      await this.$store.dispatch('System/updateNodeDetail', { detail: this.detail });
      await this.$store.dispatch('System/getTree');
      this.visible.edit = false;
    },
    async openEditDialog(node, data) {
      await this.$store.dispatch('System/getNodeDetail', { node: data })
        .then(() => { this.visible.edit = true; });
    },
    async importDPCs() {
      await this.$store.dispatch('DPC/importDPCs', { dpcs: this.readyToInsertToDBDPCs });
      this.readyToInsertToDBDPCs = [];
      this.$refs['dpc-upload'].clearFiles();
    },
    openAddNodeForm(node, data) {
      this.target.add = data.id;
      this.visible.add = true;
    },
    append(data) {
      this.visible.add = true;
      const newChild = {
        label: 'testtest',
        children: [],
      };
      if (!data.children) {
        this.$set(data, 'children', []);
      }
      data.children.push(newChild);
    },
    openDeleteNodeDialog(node, data) {
      console.log(node, data);
      this.visible.del = true;
      this.target.del = data.id;
    },
    async removeNode() {
      await this.$store.dispatch('System/removeNode', { node: this.delNode });
      this.visible.del = false;
    },
  },
  async mounted() {
    await this.$store.dispatch('System/getTree');

    this.isMounted = true;
  },
};
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
</style>
