import Command from '../../core/Command';
import Status from '../../core/Status';
import BasicResponse from '../../core/BasicResponse';
import HearBeat from '../../core/HearBeat';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);
/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function status(obj) {
  return new Status(obj.command, obj.data[0]);
}

/**
 * @param {OriginalModel} obj
 * @returns {HearBeat}
 */
function response(obj) {
  try {
    const data = {
      status: {
        88: 0, // HEART BEAT (正常)
        '00': 1, // 系統停止運轉(正常停機,無燈)
        E0: 2, // 壓力過大(紅燈亮)
        E8: 3, // SENSOR 故障
        E9: 4, // 記憶體故障
        EA: 5, // 每小時打水次數超過
        EB: 6, // PUMP 連續打水時間超時
        EC: 7, // Wifi 模組序號與DPC 序號不一致
        ED: 8, // 記憶體序號與DPC 序號不一致
        E4: 9, // Wifi busy (websocket 模式)
        EE: 10, // 缺水乾運轉(紅燈亮)
        FA: 11, // 等待回應中
        FB: 12, // DPC 與 MQTT 沒有連線
        FC: 13, // 沒有User綁定這支 SerialNumber
        FD: 14, // 伺服器錯誤 ( Exception )
        FF: 15, // 未知錯誤
      }[obj.data[0].toLocaleUpperCase()],
    };

    return new BasicResponse(
      obj.command,
      new HearBeat(data.status),
    );
  } catch (e) {
    return status(obj);
  }
}

export default {
  [Command.HEAR_BEAT]: response,
};
