import api from '@/utils/api';

export default {
  getToken: ({ auth }) => api.post('/token', { email: auth.email, password: auth.password }),
  logout: ({ token }) => api.post('/token/banned', { token }),
  refreshToken: ({ token }) => api.post('/token/refresh', { token }),
  getUserDetail: ({ token, id }) => api.get(`/vendor/${id}`, { headers: { Authorization: `Bearer ${token}` } }),
  sendResetRequest: ({ email }) => api.post('/reset', { Email: email }),
  getKey: ({ key }) => api.get(`/Reset/${key}`),
  newPW: ({ pw, key }) => api.put(`/Reset/${key}`, { Password: pw }),
};
