const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);

function padLeft(str, length) {
  if (str.length >= length) return str;
  return padLeft(`0${str}`, length);
}

export default class OriginalModel {
  /**
     *
     * @param {String} start
     * @param {String} command
     * @param {String} length
     * @param {String[]} data
     * @param {String} check
     * @param {String} end
     */
  constructor(start, command, length, data, check, end) {
    this.start = start;
    this.command = command;
    this.data = data;
    this.end = end;
    this.length = length === '' ? this.getHexLength() : length;
    console.log('constructor length', this.length);
    this.check = check === '' ? this.getHexCheck() : check;
  }

  getHexLength() {
    console.log('getHexLength num', this.getDataHex().length / 2);
    const length = padLeft(dec2hex(`${padLeft(`${this.getDataHex().length / 2}`, 2)}`), 2);
    console.log('getHexLength', length);
    return length;
  }

  getHexCheck() {
    const length = parseInt(hex2dec(this.length.toLocaleLowerCase()), 10);
    console.log('check length', length);
    const command = parseInt(hex2dec(this.command.toLocaleLowerCase()), 10);

    const hexToInt = this.data
      .map((val) => val.toLocaleLowerCase())
      .map((val) => hex2dec(val))
      .map((val) => parseInt(val, 10));
    const dataSum = hexToInt.reduce((a, b) => a + b, 0);
    const checkSum = dataSum + length + command;

    let checkSumHex = dec2hex(checkSum.toString());
    checkSumHex = checkSumHex.length === 1 ? `0${checkSumHex}` : checkSumHex;
    const checkTmp = checkSumHex[checkSumHex.length - 2] + checkSumHex[checkSumHex.length - 1];

    return checkTmp.toLocaleUpperCase();
  }

  getDataHex() {
    return this.data.reduce((a, b) => `${a}${b}`, '');
  }

  toString() {
    const fullData = this.getDataHex();
    console.log('toString', fullData);
    return `${this.start}${this.command}${this.length}${fullData}${this.check}${this.end}`.toLocaleUpperCase();
  }
}
