<template lang="pug">
div(id="app" :class="this.$route.path === '/login' ? '' : 'login_success'")
  router-view
</template>

<script>
export default {
  data() {
    return {
      tmpIsLogin: false,
    };
  },
  computed: {
  },
};
</script>

<style>
    .login_success {
        width: 100%;
        height: 100%;
    }
</style>
