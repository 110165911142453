export default class RealTimePressure {
  /**
     * @param {Number} message
     * @param {Number} status
     * @param {Number} pressure
     * @param {Number} unit
     * @param {Boolean} lock
     */
  constructor(message, status, pressure, unit, lock) {
    this.message = message;
    this.status = status;
    this.pressure = pressure;
    this.unit = unit;
    this.lock = lock;
  }
}
