import dpc from '@/utils/DPC';
import command from '@/utils/DPC/core/Command';

localStorage.url = localStorage.url ?? '';
localStorage.sn = localStorage.sn ?? '';

export default {
  namespaced: true,
  state: {
    isOpen: false,
    url: localStorage.url,
    ws: undefined,
    sn: localStorage.sn,
    basicConfig: {
      auto: 0,
      autoReset: 0,
      ct: 0,
      delay: 0,
      dt: 0,
      pDry: 0,
      pDryTime: 0,
      pMax: 0,
      pMin: 0,
      tf: undefined,
      to: undefined,
      unit: 0,
    },
  },
  getters: {
  },
  actions: {
    closeWS({ state }) {
      state.ws.close();
    },
    saveBasic({ state }) {
      const time = state.basicConfig.unit === 2 ? 10 : 100;
      const updateBasic = dpc.requestToHex(
        command.UPDATE_BASIC_CONFIG,
        {
          pMax: (state.basicConfig.pMax * time).toFixed(),
          pMin: (state.basicConfig.pMin * time).toFixed(),
          delay: state.basicConfig.delay,
          unit: state.basicConfig.unit,
          pDryTime: state.basicConfig.pDryTime,
          pDry: (state.basicConfig.pDry * time).toFixed(),
          autoReset: state.basicConfig.autoReset,
          auto: state.basicConfig.auto,

          dt: state.basicConfig.dt,
          ct: state.basicConfig.ct,
          to: state.basicConfig.to,
          tf: state.basicConfig.tf,
        },
        state.sn,
      );
      state.ws.send(updateBasic);
    },
    getBasic({ state }) {
      const basicCommand = dpc.requestToHex(
        command.GET_BASIC_CONFIG,
        [],
        state.sn,
      );
      state.ws.send(basicCommand);
    },
    restoreToOrigin({ state }) {
      const restore = dpc.requestToHex(
        command.RESET_CONFIG,
        [],
        state.sn,
      );
      state.ws.send(restore);
    },
  },
  mutations: {
    clean: (state) => {
      localStorage.url = '';
      localStorage.sn = '';

      state.url = localStorage.url;
      state.sn = localStorage.sn;
      state.ws = undefined;
    },
    init: (state, value = { wsUrl: undefined, serialNumber: undefined }) => {
      localStorage.url = value.wsUrl ?? state.url;
      localStorage.sn = value.serialNumber ?? state.sn;

      state.url = localStorage.url;
      state.sn = localStorage.sn;

      state.ws = new WebSocket(`ws://${localStorage.url}/ws`);
      state.ws.onopen = () => {
        const initData = {
          MessageType: 1,
          Data: {
            Token: '59A49AFFD805F5B0CE887F7CC1E7AEE2',
          },
        };
        state.ws.send(JSON.stringify(initData));
      };
      state.ws.onmessage = (msg) => {
        const data = dpc.responseToObj(msg.data);
        console.log('onmessage', data);
        try {
          const sendProcess = {
            START_REAL_TIME_PRESSURE: (option) => {
              state.realTime.pressure = option.pressure;
              state.realTime.lock = option.lock;
              state.realTime.unit = option.unit;
              state.realTime.status = option.status;
              state.realTime.message = option.message;

              console.log('ws', option);

              if (state.checkIsAliveID === -1) {
                state.checkIsAliveID = setInterval(() => {
                  state.isAlive = !(state.tmpMessage === state.realTime.message);
                  state.tmpMessage = state.realTime.message;
                }, 2000);
              }
            },
            STOP_REAL_TIME_PRESSURE: () => console.log('STOP_REAL_TIME_PRESSURE'),
            GET_BASIC_CONFIG: (option) => {
              console.log('GET_BASIC_CONFIG', option);
              state.basicConfig = option;
            },
            GET_ADVANCED_CONFIG: (option) => {
              console.log('GET_ADVANCED_CONFIG', option);
              state.advancedConfig = option;
            },
            LOCK_DPC: (option) => console.log('lockDpc', option),
            UNLOCK_DPC: (option) => console.log('unlockDpc', option),
            UPDATE_ADVANCED_CONFIG: (option) => {
              console.log('UPDATE_ADVANCED_CONFIG', option);
            },
            UPDATE_BASIC_CONFIG: (option) => {
              console.log('UPDATE_BASIC_CONFIG', option);
            },
            GET_DPC_INFO: (option) => console.log('GET_DPC_INFO', option),
            STOP_PUMP: (option) => console.log('STOP_PUMP', option),
            START_PUMP: (option) => console.log('START_PUMP', option),
            HEAR_BEAT: (option) => console.log('HEAR_BEAT', option),
          };

          sendProcess[data.command](data.data ?? data.status);
        } catch (e) {
          console.log('error', data);
        }
      };
    },
  },
};
