<template lang="pug">
el-row
  el-row(type="flex" justify="center")
    el-col(:span="24")
      el-table(
        :data="$store.state.Notify.notify"
        style="width: 100%"
        max-height="600"
        :empty-text="$t('emptyText')"
        )
        el-table-column(type="expand")
          template(slot-scope="props")
            el-form.demo-table-expand(label-position="left" inline)
              el-form-item(:label="$t('notification.record.content')")
                pre {{ props.row.message }}
              el-form-item(:label="$t('notification.record.receiver')")
                pre {{props.row.receiver.map(x => x.name).join(', ')}}
        el-table-column(prop="createAt" :label="$t('notification.record.date')").

        el-table-column(prop="title" :label="$t('notification.record.title')").

        el-table-column(prop="creater.name" :label="$t('notification.record.creater')").
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
  async created() {
    await this.$store.dispatch('Notify/getAllNotify');
  },
};
</script>
