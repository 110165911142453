<template lang="pug">
el-row(type="flex" justify="center" style="margin: 30px 0px 0px 0px;")
  el-dialog(
    :title="$t('systemManagement.rootTree.move')"
    :visible.sync="visible"
    width="60%"
    center
    )
    h1.
      {{ $t('systemManagement.rootTree.confirmPrefix') }}
      {{addDPCList.length}}
      {{ $t('systemManagement.rootTree.confirmSuffix') }}
    span.dialog-footer(slot="footer")
      el-button(@click="closeDialog") {{ $t('systemManagement.rootTree.CancelBtn') }}
      el-button(type="primary" @click="confirm") {{ $t('systemManagement.rootTree.ConfirmBtn') }}
  el-col
    el-tree(
      :data="tree"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :empty-text="$t('emptyText')"
      )
      span.custom-tree-node(slot-scope="{ node, data }")
        span {{ node.label }}

          el-button.badge-btn(
            @click="openDialog({status: 'mountYet', id: data.id})"
            size="small"
            type="danger"
            circle
            :disabled="color === 2 || addDPCList.length === 0"
          ) {{data.dpc.mountYet}}

          el-button.badge-btn(
            @click="openDialog({status: 'mounted', id: data.id})"
            size="small"
            type="primary"
            circle
            :disabled="color === 2 || addDPCList.length === 0"
            ) {{data.dpc.mounted}}

          el-button.badge-btn(
            @click="openDialog({status: 'registed', id: data.id})"
            size="small"
            type="success"
            circle
            :disabled="color !== 2 || addDPCList.length === 0"
          ) {{data.dpc.registed}}

        span
          el-button(
            icon="el-icon-delete-solid"
            :disabled="canDel(data)"
            type="danger"
            size="mini"
            @click="delNode(node, data)"
          )

          el-button(
            icon="el-icon-circle-plus"
            :disabled="!($store.state.Auth.purview.node && data.level <= 3)"
            type="primary"
            size="mini"
            @click="addNode(node, data)"
          ) {{ $t('systemManagement.rootTree.addNode') }}

          el-button(
            icon="el-icon-s-tools"
            :disabled="!($store.state.Auth.purview.node)"
            type="warning"
            size="mini"
            @click="editNode(node, data)"
          ) {{ $t('systemManagement.rootTree.editNode') }}

          el-button(
            icon="el-icon-turn-off"
            :disabled="!($store.state.Auth.purview.node)"
            type="success"
            size="mini"
            @click="moveDPC(node, data)"
          ) {{ $t('systemManagement.rootTree.moveDPC') }}

</template>

<script>
/* eslint-disable no-unused-vars */
import {
  mapState,
  // mapActions
} from 'vuex';

export default {
  props: {
    addDPCList: {
      type: Array,
      default: () => [],
    },
    color: {
      type: Number,
      default: 0,
    },
    targetId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      targetTmp: {
        id: 0,
        label: '',
      },
      send: {
        status: -1,
        vendorId: -1,
        ids: [],
      },
      visible: false,
    };
  },
  computed: mapState({
    tree: (state) => state.System.tree,
    target() {
      return this.targetTmp.id === 0 ? {
        id: this.tree[0].id,
        label: this.tree[0].label,
      } : this.targetTmp;
    },
  }),
  methods: {
    canDel(data) {
      return !(this.$store.state.Auth.purview.node
        && this.isNoAnyChildren(data.children)
        && this.isNoAnyDPC(data.dpc) && this.isNotSelf(data.id));
    },
    isNotSelf(id) {
      return this.$store.state.Auth.id !== id;
    },
    dataFormate(array) {
      return array
        .reduce((prucu, value, index, originArray) => {
          // 取頭
          if (index === 0) {
            prucu.push(value);
          } else if ((originArray[index] - originArray[index - 1]) !== 1) {
            prucu.push(value);
          }

          // 取尾
          if ((originArray[index + 1] - originArray[index]) !== 1) {
            prucu.push(originArray[index]);
          }

          return prucu;
        }, [])
        .reduce((prucu, value, index, originArray) => {
          if (((index + 1) % 2) === 0) {
            if (originArray[index] === originArray[index - 1]) {
              prucu.push(`${originArray[index]}`);
            } else {
              prucu.push(`${originArray[index - 1]}-${originArray[index]}`);
            }
          }
          return prucu;
        }, []);
    },
    padLeft(num, len) {
      return `${num}`.length >= len ? num : new Array(len - num.length + 1).join('0') + num;
    },
    isNoAnyDPC(dpc) {
      return dpc.mountYet === 0 && dpc.mounted === 0 && dpc.registed === 0;
    },
    isNoAnyChildren(children) {
      return (children ?? []).length === 0;
    },
    openDialog(data) {
      this.send.vendorId = data.id;
      this.send.status = {
        mountYet: 0,
        mounted: 1,
        registed: 2,
      }[data.status];
      this.send.ids = this
        .dataFormate(
          this.addDPCList
            .map((dpc) => this
              .padLeft(`${dpc.id}`, 4))
            .sort()
            .map((id) => parseInt(id, 10)),
        );
      this.visible = true;
    },
    delNode(node, data) {
      this.$emit('delNode', node, data);
    },
    addNode(node, data) {
      console.log(node, data);
      this.$emit('addNode', node, data);
    },
    editNode(node, data) {
      this.$emit('editNode', node, data);
    },
    moveDPC(node, data) {
      this.targetTmp = {
        id: data.id,
        label: data.label,
      };
    },
    async confirm() {
      await this.$store.dispatch('DPC/assignDPC', { data: this.send })
        .then(() => this.$store.dispatch('System/getTree', { id: this.targetId }))
        .then(() => this.$store.dispatch('DPC/getMountYet', { id: this.targetId }))
        .then(() => this.$store.dispatch('DPC/getMounted', { id: this.targetId }))
        .then(() => this.$store.dispatch('DPC/getRegisted', { id: this.targetId }))
        .then(() => { this.visible = false; })
        .catch((err) => console.log('error', err));
      this.visible = false;
    },
    closeDialog() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    .el-button--small.is-circle {
        padding: 4px;
    }
</style>
