export default class ServerRequest {
  /**
     *
     * @param {Number} MessageType
     * @param {RegisterRequest | DPCRequest} Data
     */
  constructor(MessageType, Data) {
    this.MessageType = MessageType;
    this.Data = Data;
  }

  toString() {
    return JSON.stringify({
      MessageType: this.MessageType,
      Data: this.Data,
    });
  }
}
