import api from '@/utils/api';

export default {
  getManagerAllRemoteInfo: async ({ token }) => api
    .get('/service', { headers: { Authorization: `Bearer ${token}` } }),
  saveRemoteInfo: async ({ token, data }) => api
    .post('/service', data, { headers: { Authorization: `Bearer ${token}` } }),
  getAllRemoteInfo: async ({ token, sn }) => api
    .get(`/service/${sn}`, { headers: { Authorization: `Bearer ${token}` } }),
  getRemoteINFO: ({ token, channelKey }) => api
    .get(`/remote/${channelKey}`, { headers: { Authorization: `Bearer ${token}` } }),
  getRemoteWS: ({ token }) => api
    .post('/remote', { headers: { Authorization: `Bearer ${token}` } }),
  getAllAlarm: async ({ token, sn }) => api
    .get(`/remote/alarm/${sn}`, { headers: { Authorization: `Bearer ${token}` } }),
};
