import Lang from '@/i18n';

const getters = {
};

const actions = {
};

const mutations = {
  setLang: (state, { lang }) => {
    state.lang = lang;
    Lang.locale = state.lang;
    localStorage.lang = lang;
  },
};

export default {
  namespaced: true,
  state: {
    lang: localStorage.lang,
  },
  getters,
  actions,
  mutations,
};
