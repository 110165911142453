import notify from '@/service/notify';

export default {
  namespaced: true,
  state: {
    review: [],
    notify: [],
  },
  getters: {
  },
  // 待審核 > 0
  // 審核通過 > 1
  // 審核失敗 > 2
  actions: {
    getAllReview: async ({ commit, rootState }) => {
      await notify.getAllReview({ token: rootState.Auth.token })
        .then((data) => commit('setReview', data))
        .catch(() => commit('setReview', [
          {
            id: 0,
            creater: {
              id: 0,
              name: 'name 0',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 0',
            content: 'content 0\ncontent',
            create: '2020-06-01',
          },
          {
            id: 1,
            creater: {
              id: 1,
              name: 'name 1',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 1',
            content: 'content 1\ncontent',
            create: '2020-06-01',
          },
          {
            id: 2,
            creater: {
              id: 2,
              name: 'name 2',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 2',
            content: 'content 2\ncontent',
            create: '2020-06-01',
          },
          {
            id: 3,
            creater: {
              id: 3,
              name: 'name 3',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 3',
            content: 'content 3\ncontent',
            create: '2020-06-01',
          },
          {
            id: 4,
            creater: {
              id: 4,
              name: 'name 4',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 4',
            content: 'content 4\ncontent',
            create: '2020-06-01',
          },
          {
            id: 5,
            creater: {
              id: 5,
              name: 'name 5',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 5',
            content: 'content 5\ncontent',
            create: '2020-06-01',
          },
          {
            id: 6,
            creater: {
              id: 6,
              name: 'name 6',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 6',
            content: 'content 6\ncontent',
            create: '2020-06-01',
          },
          {
            id: 7,
            creater: {
              id: 7,
              name: 'name 7',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 7',
            content: 'content 7\ncontent',
            create: '2020-06-01',
          },
          {
            id: 8,
            creater: {
              id: 8,
              name: 'name 8',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 8',
            content: 'content 8\ncontent',
            create: '2020-06-01',
          },
          {
            id: 9,
            creater: {
              id: 9,
              name: 'name 9',
            },
            receiver: [
              {
                id: 0,
                name: 'receiver 0',
              },
              {
                id: 1,
                name: 'receiver 1',
              },
              {
                id: 2,
                name: 'receiver 2',
              },
              {
                id: 3,
                name: 'receiver 3',
              },
              {
                id: 4,
                name: 'receiver 4',
              },
              {
                id: 5,
                name: 'receiver 5',
              },
              {
                id: 6,
                name: 'receiver 6',
              },
              {
                id: 7,
                name: 'receiver 7',
              },
              {
                id: 8,
                name: 'receiver 8',
              },
              {
                id: 9,
                name: 'receiver 9',
              },
            ],
            title: 'title 9',
            content: 'content 9\ncontent',
            create: '2020-06-01',
          },
        ]));
    },
    getAllNotify: async ({ commit, rootState }) => {
      await notify.getAllNotify({ token: rootState.Auth.token, id: rootState.Auth.id })
        .then((data) => {
          const tmpData = data;
          tmpData.data = data.data.map((notifyData) => {
            const tmpNotify = notifyData;
            const { createAt } = tmpNotify;
            const tmpResult = createAt.split('T')[0];
            tmpNotify.createAt = tmpResult;
            return tmpNotify;
          });
          console.log(data.data);
          commit('setReview', data.data.filter((notifyData) => notifyData.status === 0));
          commit('setNotify', data.data.filter((notifyData) => notifyData.status !== 0));
        })
        .catch((err) => console.log('nothing.', err));
    },
    addNotify: async ({ rootState }, { data }) => {
      await notify.addNotify({ token: rootState.Auth.token, notify: data })
        .catch((err) => console.log('nothing.', err));
    },
    updateNotify: async ({ rootState }, { notifyId, status }) => {
      await notify.updateNotify({ token: rootState.Auth.token, status, id: notifyId });
    },
  },
  mutations: {
    setReview: (state, value) => { state.review = value; },
    setNotify: (state, value) => { state.notify = value; },
  },
};
