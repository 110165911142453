/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import axios from 'axios';

const success = (resp) => resp.data;

const error = (err) => {
  console.log('系統運行發生問題，已通知廠商急救中.....');
};

// let beforeSend = config => {
//     console.log(config);
// }

// let onErr = err => {
//     console.log(err);
// }

// axios.interceptors.request.use(beforeSend, onErr)

const prefix = 'http://evakpumpsdpc.com:7002/api';

export default {
  get: (suffix, conf) => new Promise((resolve, reject) => {
    axios.get(`${prefix}${suffix}`, conf)
      .then((resp) => resolve(success(resp)))
      .catch((err) => {
        error(err);
        reject(err);

        return err;
      });
  }),
  post: (suffix, data, conf) => new Promise((resolve, reject) => {
    axios.post(`${prefix}${suffix}`, data, conf)
      .then((resp) => resolve(success(resp)))
      .catch((err) => {
        error(err);
        reject(err);

        return err;
      });
  }),
  put: (suffix, data, conf) => new Promise((resolve, reject) => {
    axios.put(`${prefix}${suffix}`, data, conf)
      .then((resp) => resolve(success(resp)))
      .catch((err) => {
        error(err);
        reject(err);

        return err;
      });
  }),
  del: (suffix, conf) => new Promise((resolve, reject) => {
    axios.delete(`${prefix}${suffix}`, conf)
      .then((resp) => resolve(success(resp)))
      .catch((err) => {
        error(err);
        reject(err);

        return err;
      });
  }),
};
