import {
  isServerResponse,
  serverResponseToObj,
  firstTransform,
} from './core';
import Command from './command';
import Response from './response';
import RegisterSuccessResponse from './core/RegisterSuccessResponse';
import ServerRequest from './core/ServerRequest';
import DPCRequest from './core/DPCRequest';

export default {
  /**
     * @param {String} response
     * @returns {BasicResponse | RegisterSuccessResponse | ErrorResponse}
     */
  responseToObj: (response) => {
    console.log('dpc core > response', response);
    if (isServerResponse(response)) {
      const serverResponse = serverResponseToObj(response);
      if (serverResponse.Data instanceof RegisterSuccessResponse) {
        return serverResponse;
      } if (serverResponse.MessageType === 2) {
        const obj = firstTransform(serverResponse.Data.HexString);
        return Response[obj.command](obj);
      }
      return serverResponse;
    }
    const obj = firstTransform(response);
    return Response[obj.command](obj);
  },
  /**
     * @param {String} command
     * @param {any[]} data
     * @returns {String}
     */
  requestToHex: (command, data, sn) => {
    console.log('dpc core > request', command);
    console.log(data);
    console.log(sn);
    let result = '';

    const sendMSG = Command[command](data).toString();
    if (sn !== undefined) {
      result = new ServerRequest(2, new DPCRequest(sn, sendMSG)).toString();

      if (command === 'XX') {
        result = sendMSG;
      }
    } else {
      result = Command[command](data).toString();
    }

    return result;
  },
};
