export default class DPCRequest {
  /**
     *
     * @param {String} SerialNumber
     * @param {String} HexString
     */
  constructor(SerialNumber, HexString) {
    this.SerialNumber = SerialNumber;
    this.HexString = HexString;
  }
}
