<template lang="pug">
div(style="margin: 0px 0px 0px 0px")
  el-form
    el-form-item(:label="$t('notification.message.title')" prop="notifyTitle" label-width="80px")
      el-input(v-model="notify.title" :placeholder="$t('notification.message.titleInput')")
    el-form-item(
      :label="$t('notification.message.content')"
      prop="notifyContent"
      label-width="80px"
      )
      el-input(
        v-model="notify.message"
        type="textarea"
        :rows="5"
        :placeholder="$t('notification.message.contentINput')"
      )
    el-form-item(
      :label="$t('notification.message.receiver')"
      prop="notifyReceiver"
      label-width="80px"
      )
      root-tree(ref="tree")
  el-button(
    @click="send"
    type="primary"
    :disabled="isEmpty"
    ) {{ $t('notification.message.submit') }}
</template>

<script>
import RootTree from '../components/rootTree/index.vue';

export default {
  components: {
    RootTree,
  },
  data() {
    return {
      isMounted: false,
      notify: {
        title: '',
        message: '',
        status: 0,
        receiver: [],
        sender: 0,
      },
    };
  },
  computed: {
    isEmpty() {
      return this.notify.title === '' || this.notify.message === '' || this.receiver.length === 0;
    },
    receiver() {
      return this.isMounted ? this.$refs.tree.data : [];
    },
    sender() {
      return this.$store.state.Auth.id;
    },
  },
  methods: {
    send() {
      this.notify.sender = this.sender;
      this.notify.receiver = this.receiver;
      this.notify.status = this.$store.state.System.tree[0].level === 1 ? 1 : 0;

      console.log(this.notify);

      this.$store.dispatch('Notify/addNotify', { data: this.notify })
        .then(() => {
          this.$notify({
            title: this.$t('notyify.successTitle'),
            message: this.$t('notyify.successTitle'),
            type: 'success',
          });
          this.$refs.tree.setCheckedKeys([]);
          this.notify = {
            title: '',
            message: '',
            status: 0,
            receiver: [],
            sender: 0,
          };
        });
    },
  },
  async mounted() {
    await this.$store.dispatch('System/getTree');
    // .then(() => this.$store.dispatch('Notify/getAllNotify'))
    this.isMounted = true;
  },
};
</script>
