<template lang="pug">
div
  el-table(:data="infos" style="width: 100%")
    el-table-column(type="expand")
      template(slot-scope="props")
        el-form.demo-table-expand(label-position="left" inline)
          el-form-item(:label="$t('serviceRecord.remark')")
            span {{ props.row.commit }}
    el-table-column(:label="$t('serviceRecord.date')" prop="createAt" sortable)
    el-table-column(label="SN" prop="sn" sortable)
</template>

<style>
  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>

<script>
import remote from '@/service/remote';

export default {
  data() {
    return {
      infos: [],
    };
  },
  async mounted() {
    console.log('moment');

    const infosTmp = await remote.getManagerAllRemoteInfo({
      token: this.$store.state.Auth.token,
    });

    this.infos = infosTmp.data.map((info) => ({
      createAt: info.remoteStart,
      commit: info.commit,
      sn: info.sn,
    }));
  },
};
</script>
