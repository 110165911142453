import api from '@/utils/api';

export default {
  importDPCs: ({ token, dpcs }) => api.post('/device/import', dpcs, { headers: { Authorization: `Bearer ${token}` } }),
  getAllModels: ({ token }) => api.get('/device/model', { headers: { Authorization: `Bearer ${token}` } }),
  getMountYet: ({ token }, { id }) => api.post('/device/query', { vendorId: id, status: 0 }, { headers: { Authorization: `Bearer ${token}` } }),
  getMounted: ({ token }, { id }) => api.post('/device/query', { vendorId: id, status: 1 }, { headers: { Authorization: `Bearer ${token}` } }),
  getRegisted: ({ token }, { id }) => api.post('/device/query', { vendorId: id, status: 2 }, { headers: { Authorization: `Bearer ${token}` } }),
  getAllAlert: ({ token, sn }) => api.get(`/remote/alarm/${sn}`, { headers: { Authorization: `Bearer ${token}` } }),
  assignDPC: ({ token, data }) => api.put('/device/assign', data, { headers: { Authorization: `Bearer ${token}` } }),
  getAllMappingYet: ({ token }) => api.get('/device/mappingyet', { headers: { Authorization: `Bearer ${token}` } }),
};
