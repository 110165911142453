<template lang="pug">
el-row
  el-row(type="flex" justify="center")
    el-col(:span="24")
      el-upload.upload-demo(
        ref="dpc-upload"
        drag
        accept="application/json"
        :limit="1"
        :multiple="false"
        :auto-upload="false"
        action
        :on-change="onFileChange"
      )
        i.el-icon-upload
        .el-upload__text
          | {{ $t('importDPC.dragDescription')}}
          em {{ $t('importDPC.dragDescriptionSub')}}
        .el-upload__tip(slot="tip") {{ $t('importDPC.importRequire')}}
  el-row(type="flex" justify="center")
    el-col(:span="24")
      el-table(
        :data="dpcList"
        style="width: 100%"
        height="500"
        max-height="500"
        :empty-text="$t('emptyText')"
        )
        el-table-column(prop="date" :label="$t('importDPC.manufactureDate')").

        el-table-column(prop="SN" label="SN" :sortable="true").

        el-table-column(prop="name" :label="$t('importDPC.modelName')").

        el-table-column(prop="modelName" :label="$t('importDPC.modelNumber')").

        el-table-column(prop="num" :label="$t('importDPC.number')" :sortable="true").

  el-row(type="flex" justify="center" style="margin: 20px 0px 0px 0px;")
    el-col(:span="4")
      el-button(
        @click="send"
        type="success"
        :disabled="dpcList.length === 0"
      ) {{ $t('importDPC.submitBtn')}}
    el-col(:span="4")
      el-button(
        @click="clean"
        type="warning"
        :disabled="dpcList.length === 0"
      ) {{ $t('importDPC.clearBtn')}}
</template>

<script>

export default {
  data() {
    return {
      dpcList: [],
    };
  },
  methods: {
    onFileChange(data) {
      const reader = new FileReader();
      reader.readAsText(data.raw, 'UTF-8');
      reader.onload = (e) => {
        const tmpData = e.currentTarget.result;
        let jsonData = JSON.parse(tmpData);
        try {
          const typeSN = typeof (jsonData[0].SN);
          const typeUUID = typeof (jsonData[0].UUID);
          const typeBK = typeof (jsonData[0].BK);
          const typeName = typeof (jsonData[0].Name);
          const typeModel = typeof (jsonData[0].Model);
          const tmpArr = [
            typeSN,
            typeUUID,
            typeBK,
            typeName,
            typeModel];
          console.log(tmpArr);

          jsonData = jsonData.map((d) => {
            const tmpD = d;
            tmpD.date = `20${d.SN[2]}${d.SN[3]}-${d.SN[4]}${d.SN[5]}-${d.SN[6]}${d.SN[7]}`;
            tmpD.num = `${d.SN[8]}${d.SN[9]}${d.SN[10]}${d.SN[11]}`;
            tmpD.modelName = this.$store.state.DPC.models
              .find((model) => model.id === d.Model).name;
            tmpD.name = d.Name;
            return tmpD;
          });

          if (new Set(jsonData.map((d) => d.date)).size > 1) {
            throw new Error('error');
          }

          this.dpcList = jsonData;
          this.$refs['dpc-upload'].clearFiles();
        } catch {
          console.log('檔案內容格式錯誤', jsonData);
          this.$notify({
            title: this.$t('notyify.errotTitle'),
            message: this.$t('notyify.wrongFormatMsg'),
            type: 'error',
          });
          this.$refs['dpc-upload'].clearFiles();
        }
      };
    },
    clean() {
      this.dpcList = [];
    },
    async send() {
      const data = this.dpcList.map((d) => ({
        BK: d.BK.toUpperCase(),
        SN: d.SN,
        UUID: d.UUID.toUpperCase(),
        name: d.name,
        modelId: d.Model,
      }));
      await this.$store.dispatch('DPC/importDPCs', { dpcs: data })
        .then(() => {
          this.$notify({
            title: this.$t('notyify.successTitle'),
            message: this.$t('notyify.successMsg'),
            type: 'success',
          });
          this.clean();
        })
        .catch((err) => {
          this.$notify({
            title: this.$t('notyify.errotTitle'),
            message: this.$t('notyify.snDuplicateMsg'),
            type: 'error',
          });
          console.log(err.response);
        });
    },
  },
};
</script>
