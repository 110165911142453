import social from '@/service/social';

localStorage.social = localStorage.social ?? '[]';

export default {
  namespaced: true,
  state: {
    social: JSON.parse(localStorage.social),
  },
  getters: {
  },
  actions: {
    getAll: async ({ commit, rootState }) => {
      await social.getAll({ token: rootState.Auth.token })
        .then((data) => commit('setSocial', data.data));
    },
  },
  mutations: {
    setSocial: (state, value) => {
      state.social = value;
      localStorage.social = JSON.stringify(value);
    },
  },
};
