import Command from '../../core/Command';
import Status from '../../core/Status';

/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function status(obj) {
  return new Status(obj.command, obj.data[0]);
}

/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function response(obj) {
  return status(obj);
}

export default {
  [Command.UPDATE_BASIC_CONFIG]: response,
};
