import Moment from 'moment';
import Command from '../../core/Command';
import Status from '../../core/Status';
import BasicResponse from '../../core/BasicResponse';
import BasicConfig from '../../core/BasicConfig';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);

/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function status(obj) {
  return new Status(obj.command, obj.data[0]);
}

/**
 * @param {OriginalModel} obj
 * @returns {BasicResponse}
 */
function response(obj) {
  try {
    const data = {
      // Basic config
      pMax: parseInt(hex2dec(`${obj.data[0]}${obj.data[1]}`.toLocaleLowerCase()), 10),
      pMin: parseInt(hex2dec(`${obj.data[2]}${obj.data[3]}`.toLocaleLowerCase()), 10),
      delay: parseInt(hex2dec(obj.data[4].toLocaleLowerCase()), 10),
      unit: {
        '01': 0, // bar value/100
        '02': 1, // kgf/cm2 value/100
        '04': 2, // psi value/10
      }[obj.data[5].toLocaleUpperCase()],
      pDryTime: parseInt(hex2dec(obj.data[6].toLocaleLowerCase()), 10),
      pDry: parseInt(hex2dec(`${obj.data[7]}${obj.data[8]}`.toLocaleLowerCase()), 10),
      autoReset: parseInt(hex2dec(`${obj.data[9]}${obj.data[10]}`.toLocaleLowerCase()), 10),
      auto: parseInt(hex2dec(obj.data[11].toLocaleLowerCase()), 10),

      // Advanced config
      dt: parseInt(hex2dec(obj.data[12].toLocaleLowerCase()), 10),
      ct: parseInt(hex2dec(obj.data[13].toLocaleLowerCase()), 10),
      to: Moment.duration({
        hours: parseInt(hex2dec(obj.data[14][0].toLocaleLowerCase()), 10),
        minutes: parseInt(hex2dec(obj.data[14][1].toLocaleLowerCase()), 10) * 10,
      }),
      tf: Moment.duration({
        hours: parseInt(hex2dec(obj.data[15][0].toLocaleLowerCase()), 10),
        minutes: parseInt(hex2dec(obj.data[15][1].toLocaleLowerCase()), 10) * 10,
      }),
    };

    const trans = [100, 100, 10];

    const result = new BasicResponse(
      obj.command,
      new BasicConfig(
        data.pMax / trans[data.unit],
        data.pMin / trans[data.unit],
        data.delay,
        data.unit,
        data.pDryTime,
        data.pDry / trans[data.unit],
        data.autoReset,
        data.auto,

        data.dt,
        data.ct,
        data.to,
        data.tf,
      ),
    );

    return result;
  } catch (e) {
    return status(obj);
  }
}

export default {
  [Command.GET_BASIC_CONFIG]: response,
};
