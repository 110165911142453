import Vue from 'vue';
import Vuex from 'vuex';

import Auth from '@/store/auth';
import System from '@/store/system';
import DPC from '@/store/dpc';
import Notify from '@/store/notify';
import Permission from '@/store/permission';
import Remote from '@/store/remote';
import Social from '@/store/social';
import WS from '@/store/ws';
import Logo from '@/assets/logo.png';
import Lang from '@/store/lang';
import Reset from '@/store/reset';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Logo,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    WS,
    Auth,
    System,
    DPC,
    Notify,
    Permission,
    Remote,
    Social,
    Lang,
    Reset,
  },
});
