export default class Status {
  /**
     *
     * @param {String} status
     */
  constructor(command, status) {
    this.command = {
      '00': 'START_REAL_TIME_PRESSURE',
      '7F': 'STOP_REAL_TIME_PRESSURE',
      '01': 'GET_BASIC_CONFIG',
      '02': 'GET_ADVANCED_CONFIG',
      '0C': 'LOCK_DPC',
      '0D': 'UNLOCK_DPC',
      11: 'UPDATE_BASIC_CONFIG',
      12: 'UPDATE_ADVANCED_CONFIG',
      20: 'GET_DPC_INFO',
      70: 'STOP_PUMP',
      '7A': 'START_PUMP',
      30: 'HEAR_BEAT',
      77: 'RESET_CONFIG',
    }[command];

    this.status = {
      '00': 0,
      E1: 1,
      E2: 2,
      E3: 3,
    }[status];
  }
}
