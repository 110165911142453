import Moment from 'moment';
import Command from '../../core/Command';
import Status from '../../core/Status';
import BasicResponse from '../../core/BasicResponse';
import AdvancedConfig from '../../core/AdvancedConfig';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);
/**
 * @param {OriginalModel} obj
 * @returns {Status}
 */
function status(obj) {
  return new Status(obj.command, obj.data[0]);
}

/**
 * @param {OriginalModel} obj
 * @returns {BasicResponse | Status}
 */
function response(obj) {
  try {
    const data = {
      dt: parseInt(hex2dec(obj.data[0].toLocaleLowerCase()), 10),
      ct: parseInt(hex2dec(obj.data[1].toLocaleLowerCase()), 10),
      to: Moment.duration({
        hours: parseInt(hex2dec(obj.data[2][0].toLocaleLowerCase()), 10),
        minutes: parseInt(hex2dec(obj.data[2][1].toLocaleLowerCase()), 10) * 10,
      }),
      tf: Moment.duration({
        hours: parseInt(hex2dec(obj.data[3][0].toLocaleLowerCase()), 10),
        minutes: parseInt(hex2dec(obj.data[3][1].toLocaleLowerCase()), 10) * 10,
      }),
    };

    return new BasicResponse(obj.command,
      new AdvancedConfig(data.dt, data.ct, data.to, data.tf));
  } catch (e) {
    return status(obj);
  }
}

export default {
  [Command.GET_ADVANCED_CONFIG]: response,
};
