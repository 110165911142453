import Command from '../../core/Command';
import OriginalModel from '../../core/OriginalModel';

const anyBase = require('any-base');

export const dec2hex = anyBase(anyBase.DEC, anyBase.HEX);
export const hex2dec = anyBase(anyBase.HEX, anyBase.DEC);

/**
 * @param {AdvancedConfig} data
 * @returns {String[]}
 */
function adapter(data) {
  let dt = dec2hex((data.dt).toString());
  dt = dt.length === 1 ? `0${dt}` : dt;

  let ct = dec2hex((data.ct).toString());
  ct = ct.length === 1 ? `0${ct}` : ct;

  const toH = dec2hex((data.to.hours()).toString());
  const toM = dec2hex(data.to.minutes().toString()[0]);
  const to = `${toH}${toM}`;

  const tfH = dec2hex((data.tf.hours()).toString());
  const tfM = dec2hex(data.tf.minutes().toString()[0]);
  const tf = `${tfH}${tfM}`;

  return [dt, ct, to, tf];
}

export default {
  [Command.UPDATE_ADVANCED_CONFIG]: (data) => new OriginalModel('55', Command.UPDATE_ADVANCED_CONFIG, '', adapter(data), '', 'AA'),
};
