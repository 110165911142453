import GetBasicConfig from './GetBasicConfig';
import GetAdvancedConfig from './GetAdvancedConfig';
import StartRealTimePressure from './StartRealTimePressure';
import StopRealTimePressure from './StopRealTimePressure';
import UpdateBasicConfig from './UpdateBasicConfig';
import UpdateAdvancedConfig from './UpdateAdvancedConfig';
import StartPump from './StartPump';
import StopPump from './StopPump';
import Lock from './Lock';
import Unlock from './Unlock';
import HearBeat from './HearBeat';

export default {
  ...StartRealTimePressure,
  ...StopRealTimePressure,
  ...GetBasicConfig,
  ...GetAdvancedConfig,
  ...UpdateBasicConfig,
  ...UpdateAdvancedConfig,
  ...StartPump,
  ...StopPump,
  ...Lock,
  ...Unlock,
  ...HearBeat,
};
