<template lang="pug">
el-row(type="flex" justify="center")
  el-col(:span="20")
    el-tree(
      ref="tree"
      :data="tree"
      node-key="id"
      default-expand-all
      :expand-on-click-node="false"
      :check-strictly="true"
      show-checkbox
      :empty-text="$t('emptyText')"
    )
      span.custom-tree-node(slot-scope="{ node }")
        span {{ node.label }}
</template>

<script>
import {
  mapState,
  // mapActions
} from 'vuex';

export default {
  props: {
  },
  data() {
    return {
      isMounted: false,
    };
  },
  computed: mapState({
    tree: (state) => state.System.tree,
    data() {
      return this.isMounted ? this.$refs.tree.getCheckedKeys() : [];
    },
  }),
  methods: {
    setCheckedKeys(data) {
      this.$refs.tree.setCheckedKeys(data);
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style scoped>
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    .el-button--small.is-circle {
        padding: 4px;
    }
</style>
