<template lang="pug">
el-dialog(
  :title="$t('langDialog.title')"
  :visible.sync="visible"
  :before-close="closeDialog"
  width="30%"
  center
)
  template
    el-table(:data="tableData" style="width: 150px")
      el-table-column(prop="lang" :label="$t('language')")
        template(slot-scope="scope")
          el-button(@click="changeLang(scope.$index, scope.row)" prop="lang" type="text").
           {{ scope.row.lang }}

</template>

<script>
export default {
  data() {
    return {
      tableData: [{
        lang: '中文',
        local: 'zh-tw',
      }, {
        lang: 'English',
        local: 'en',
      }],
    };
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  methods: {
    closeDialog() {
      this.$emit('update:visible', false);
    },
    changeLang(index) {
      this.$store.commit('Lang/setLang', { lang: this.tableData[index].local });
      this.closeDialog();
    },
  },
};
</script>
