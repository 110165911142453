import authAPI from '@/service/auth';

export default {
  namespaced: true,
  state: {
    isReset: false,
    sendRequest: false,
    isGetKeySuccess: false,
  },

  getters: {
  },

  actions: {
    sendResetRequest: async ({ commit }, email) => {
      console.log('email: ', email);
      await authAPI.sendResetRequest(email)
        .then(() => {
          console.log('sendResetRequest success');
          commit('setRequest', true);
        }, (err) => console.log('sendResetRequest fail: ', err));
    },
    getKey: async ({ commit }, key) => {
      console.log('key: ', key);
      await authAPI.getKey({ key })
        .then((res) => {
          console.log('getKey success');
          console.log('res: ', res);
          commit('setGetKeySuccess', true);
        }, (err) => console.log('getKey fail: ', err));
    },
    sendNewPW: async ({ commit }, payload) => {
      console.log('sendNewPW/pw: ', payload.pw);
      console.log('sendNewPW/key: ', payload.key);
      await authAPI.newPW(payload)
        .then(() => {
          console.log('send new pw success');
        }, (err) => console.log('send new pw fail: ', err))
        .then(() => {
          commit('setReset', true);
        });
    },
  },

  mutations: {
    setReset: (state, value) => {
      state.isReset = value;
    },
    setRequest: (state, value) => {
      state.sendRequest = value;
    },
    setGetKeySuccess: (state, value) => {
      state.isGetKeySuccess = value;
    },
  },
};
