import remote from '@/service/remote';

export default {
  namespaced: true,
  state: {
    ws: localStorage.ws,
    sn: localStorage.sn,
  },
  getters: {
  },
  actions: {
    getRemoteWS: async ({ commit, rootState }, { channelKey }) => {
      await remote.getRemoteWS({ token: rootState.Auth.token, channelKey })
        .then((data) => {
          commit('setWS', data.data.wsUrl);
          commit('setSN', data.data.serialNumber);
        })
        .catch((err) => console.log('nothing.', err));
    },
    getAllAlarm: async ({ state, rootState }) => {
      await remote.getAllAlarm({ token: rootState.Auth.token, sn: state.sn });
    },
  },
  mutations: {
    setWS: (state, value) => {
      state.ws = value;
      localStorage.ws = value;
    },
    setSN: (state, value) => {
      state.sn = value;
      localStorage.sn = value;
    },
  },
};
