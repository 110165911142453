<template lang="pug">
el-row
  el-row
    el-col(:span="24")
      h1 {{ $t('systemManagement.rigister.filter') }} {{selectedData.length}}/{{data.length}}
  el-row(type="flex" justify="center")
    el-col(:span="4") {{ $t('systemManagement.rigister.interval') }}

    el-col(:span="18")
      el-slider(ref="slider" range @change="onSlider" :min="min" :max="max")

  el-row
    el-col(:span="24")
      el-table(
        ref="filter-table"
        :data="data"
        height="300"
        @selection-change="onSelected"
        style="width: 100%"
        :empty-text="$t('emptyText')"
      )
        el-table-column(
          v-if="$store.state.Auth.purview.node"
          type="selection"
          width="55"
        )

        el-table-column(
          prop="date"
          :label="$t('systemManagement.rigister.manufactureDate')"
          sortable
          width="180"
          column-key="date"
          :filters="dates"
        )

        el-table-column(
          prop="sn"
          label="SN"
          width="180"
        )

        el-table-column(
          prop="type"
          :label="$t('systemManagement.rigister.modelName')"
          :filters="types"
          :filter-method="filterHandler"
          width="180"
        )

        el-table-column(
          prop="module"
          :label="$t('systemManagement.rigister.hardwareVersion')"
          width="100"
          :filters="modules"
          :filter-method="filterHandler"
        )

        el-table-column(
          prop="num"
          :label="$t('systemManagement.rigister.number')"
          align="center"
          :sortable="true"
          header-align="center"
        )

</template>

<script>
import {
  mapState,
  // mapActions
} from 'vuex';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // value: [4, 8],
      isMounted: false,
      selectedData: [],
    };
  },
  computed: mapState({
    max() {
      const max = Math.max(...this.data.map((dpc) => parseInt(dpc.num, 10)));
      return max;
    },
    min() {
      const min = Math.min(...this.data.map((dpc) => parseInt(dpc.num, 10)));
      return min;
    },
    dates(state) {
      return [...new Set(state.DPC[this.name].map((x) => x.serialNumber.slice(2, 8)))]
        .map((type) => ({
          text: type,
          value: type,
        }));
    },
    types(state) {
      return [...new Set(state.DPC[this.name].map((x) => x.displayName))]
        .map((type) => ({
          text: type,
          value: type,
        }));
    },
    modules(state) {
      return [...new Set(state.DPC[this.name].map((x) => x.module))]
        .map((module) => ({
          text: module,
          value: module,
        }));
    },
    data(state) {
      const data = state.DPC[this.name]
        .map((dpc) => ({
          sn: dpc.serialNumber,
          id: dpc.id,
          num: dpc.serialNumber.slice(8),
          type: dpc.displayName,
          module: dpc.module,
          date: dpc.serialNumber.slice(2, 8),
        }));

      return data;
    },
  }),
  methods: {
    onSlider(range) {
      const afterFilterData = this.data
        .filter((dpc) => parseInt(dpc.num, 10) >= range[0])
        .filter((dpc) => parseInt(dpc.num, 10) <= range[1]);

      this.$refs['filter-table'].clearSelection();
      afterFilterData.forEach((row) => {
        this.$refs['filter-table'].toggleRowSelection(row);
      });
    },
    onSelected(selected) {
      this.selectedData = selected;
    },
    filterHandler(value, row, column) {
      const { property } = column;
      return row[property] === value;
    },
  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
