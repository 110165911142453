import permission from '@/service/permission';

localStorage.permission = localStorage.permission ?? '[]';

export default {
  namespaced: true,
  state: {
    permission: JSON.parse(localStorage.permission),
  },
  getters: {
  },
  actions: {
    getAllPermission: async ({ commit, rootState }) => {
      await permission.getAllPermission({ token: rootState.Auth.token })
        .then((data) => {
          commit('setPermission', data.data);
        });
    },
  },
  mutations: {
    setPermission: (state, value) => {
      state.permission = value;
      localStorage.permission = JSON.stringify(value);
    },
  },
};
