<template lang="pug">
el-row
  el-row(type="flex" justify="center")
    el-col(:span="24")
      el-table(
        :data="$store.state.Notify.review"
        style="width: 100%"
        max-height="600"
        :empty-text="$t('emptyText')"
      )
        el-table-column(type="expand")
          template(slot-scope="props")
            el-form.demo-table-expand(label-position="left" inline)
              el-form-item(:label="$t('notification.review.content')")
                pre {{ props.row.message }}
              el-form-item(:label="$t('notification.review.receiver')")
                span {{props.row.receiver.map(x => x.name).join(', ')}}
        el-table-column(prop="createAt" :label="$t('notification.review.date')")
        el-table-column(prop="title" :label="$t('notification.review.title')")
        el-table-column(prop="creater.name" :label="$t('notification.review.creater')")
        el-table-column(:label="$t('notification.review.operate')" fixed="right" min-width="100px")
          template(slot-scope="scope")
            el-button(
              icon="el-icon-success"
              @click.native.prevent="review(scope, 1)"
              type="success"
              size="small"
            ) {{ $t('notification.review.confirm') }}
            el-button(
              icon="el-icon-error"
              @click.native.prevent="review(scope, 2)"
              type="danger"
              size="small"
            ) {{ $t('notification.review.submit') }}
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
    async review(data, status) {
      await this.$store.dispatch('Notify/updateNotify', {
        notifyId: data.row.id,
        status: {
          status,
        },
      })
        .then(() => this.$store.dispatch('Notify/getAllNotify'))
        .then(() => {
          this.$notify({
            title: this.$t('notyify.successTitle'),
            message: this.$t('notyify.successTitle'),
            type: 'success',
          });
        });
    },
  },
  async created() {
    await this.$store.dispatch('Notify/getAllNotify');
  },
};
</script>
