<template lang="pug">
#main.text-light.placeholder-light.remove-focus.d-flex.flex-column.justify-content-center
  .pt-3.mb-3.d-flex.justify-content-center.flex-column.align-items-center
    img.login-logo(:src="images.logo" alt)
    h1.mt-5.
      Customer Service Management
  form.d-flex.justify-content-center.flex-column.align-items-center(
    :model="auth"
    ref="form"
  )
    .input-area
      .mt-5.border-bottom
        input.w-100.text-white.h2.mb-0(
          :placeholder="$t('loginForm.account')"
          type="email"
          v-model="auth.email"
          required
        )
      .mt-4.border-bottom
        input.w-100.text-white.h2.mb-0(
          :placeholder="$t('loginForm.password')"
          type="password"
          v-model="auth.password"
          required
        )
    button.btn.btn-danger.mt-5.px-5(
      type="submit"
      @click="getToken"
    ).
      {{ $t('loginBtn') }}
    button.btn.btn-link.text-secondary.btn-lg.mt-2(@click="gotoForgetPW").
        {{ $t('forgetPWBtn') }}
    button.btn.btn-link.text-white.btn-lg.mt-4(@click="openDialog")
      font-awesome-icon(:icon="['fas', 'globe-asia']").
      span.ml-2.
        {{ $t('currentLanguage') }}
    LangDialog(:visible='isOpen' @update:visible="closeDialog")
</template>

<script>
import LangDialog from '@/components/LangDialog/index.vue';

export default {
  components: {
    LangDialog,
  },
  data() {
    return {
      auth: {
        email: 'admin@gmail.com',
        password: '123456',
      },
      images: {
        logo: require('@/assets/logo.png'),
      },
      isOpen: false,
    };
  },
  methods: {
    async getToken() {
      if (!this.$refs.form.checkValidity()) {
        return;
      }
      const getTokenErr = await this.$store.dispatch('Auth/getToken', { auth: this.auth })
        .catch((err) => err);

      if (getTokenErr) {
        this.errNotify();
        return;
      }

      const getPermissionErr = await this.$store.dispatch('Permission/getAllPermission')
        .catch((err) => console.log('get all permission error.', err));

      if (getPermissionErr) {
        this.errNotify();
        return;
      }

      const getUserDetailErr = await this.$store.dispatch('Auth/getUserDetail')
        .catch((err) => console.log('get all user deatil error.', err));

      if (getUserDetailErr) {
        this.errNotify();
        return;
      }

      const getTreeErr = await this.$store.dispatch('System/getTree')
        .catch((err) => console.log('get tree error.', err));

      if (getTreeErr) {
        this.errNotify();
        return;
      }

      const getAllModelsErr = await this.$store.dispatch('DPC/getAllModels')
        .catch((err) => console.log('get all models error', err));

      if (getAllModelsErr) {
        this.errNotify();
        return;
      }

      const getAllErr = await this.$store.dispatch('Social/getAll')
        .catch((err) => console.log('get all social error.', err));

      if (getAllErr) {
        this.errNotify();
        return;
      }

      this.$router.push('/');
    },
    openDialog() {
      this.isOpen = true;
    },
    closeDialog() {
      this.isOpen = false;
    },
    gotoForgetPW() {
      this.$router.push('/forgetPW');
    },
    errNotify() {
      this.$notify({
        title: '警告',
        message: '登入錯誤',
        type: 'error',
      });
    },
  },
  mounted() {
  },
};
</script>

<style>
#app {
  height: 100%;
}

#main {
  height: 100%;
  background: linear-gradient(0deg, #00425C 32%, #31698F 66%);
}

.login-logo {
  height: 100px;
}

.input-area {
  width: 400px;
}

input {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

::placeholder {
  color: white;
}
</style>
